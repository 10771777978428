<template>
  <div>
    <ShoLoader :loading="fetching" />
    <ShoModal
      v-if="!fetching"
      title="Complete Your Profile"
      @close="onClose"
    >
   
      <div class="form-copy">
        <p>
          Create your Showtime Anytime profile to take advantage of personalized features like My List, Resume Watching and more.
        </p>
      </div>

      <ShoForm class="registration-form" @close-form="onClose" @submit="onSubmit">
        <p v-if="formError" class="error form-error">{{ formError }}</p>

        <template v-for="(fieldGroup, groupName) in registrationFields">

          <h3 v-if="partitionedForms" :key="`group-${groupName}`" class="field-group-header">{{ groupName }}</h3>

          <div
            v-for="(field, idx) in fieldGroup"
            :id="`form-field-${groupName}-${idx}`"
            :key="`field-${groupName}-${idx}`"
            :class="['form-field', formField(field.id) === 'ShoCheckbox' ? 'sho-checkbox-container' : '']"
          >
            <component
              :is="formField(field.id)"
              v-if="fieldCanDisplay(field.id)"
              v-model="fields[field.id]"
              :animated="true"
              :inline-label="true"
              :input-name="field.id"
              :input-type="field.type"
              :error="errors[field.id]"
              :required="field.required"
              :disabled="isFieldLocked(field.pii)"
              :class="{pii: field.pii}"
              :name="field.label"
              :label="field.label"
              :maxlength="field.maxLength"
              @input="onInput(field.pii, field.id, $event.target.value)"
              @update="onInput(field.pii, field.id, $event.target.value)"
            />
          </div>
        </template>

        <ShoButton variant="primary" :disabled="isSubmitting" @click="onSubmit">
          Complete My Profile
        </ShoButton>

        <div class="form-legend">
          <span class="red">*</span> Required
        </div>
      </ShoForm>

      <ShoLoader :loading="isSubmitting" />
    
    </ShoModal>
  </div>
</template>

<script>
import GenderSelect from '@components/settings/personal-info/GenderSelect.vue';
import ShoButton from '@components/ui/ShoButton.vue';
import ShoCheckbox from '@components/ui/ShoCheckbox.vue';
import ShoInput from '@components/ui/ShoInput.vue';
import ShoForm from '@components/ui/ShoForm.vue';
import ShoModal from '@components/ui/ShoModal.vue';
import ShoLoader from '@components/ui/ShoLoader.vue';
import ShoMaskedDate from '@components/ui/ShoMaskedDate.vue';

import { ACCOUNT_TYPES, MSO_TYPES } from '@utils/constants.js';
import { getCookie, setCookie } from '@utils/cookies.js';
import { mapActions, mapState } from 'vuex';
import { validEmail, validateIsOver18 } from '@utils/validate.js';

/**
 * @displayName RegistrationForm
 */
export default {
  name: 'RegistrationForm',

  components: {
    GenderSelect,
    ShoButton,
    ShoCheckbox,
    ShoInput,
    ShoForm,
    ShoModal,
    ShoLoader,
    ShoMaskedDate,
  },

  emits: ['form-close', 'form-submitted'],

  data () {
    return {
      fields: {},
      fieldsWithPii: {},
      isAnyPiiDataEntered: false,
      isAnyFieldLocked: false,
      isSubmitting: false,
      errors: {},
      formError: null,
    };
  },

  computed: {
    // Using alias' because mso and user both have accountType field.
    ...mapState('mso', 
      {
        fetching: 'fetching', 
        registration: 'registration',  
        msoAccountType: 'accountType', 
        parentalControls: 'parentalControls', 
        partitionedForms: 'partitionedForms', 
      }
    ),
    ...mapState('user', ['accountType', 'mso', 'tveUserId']),

    /**
     * Returns set of registration fields for form depending on user's account type
     * and MSO settings
     * @returns {array}
     */
    registrationFields () {
      const acctType = Object.keys(ACCOUNT_TYPES).find((key) => ACCOUNT_TYPES[key] === this.accountType);
      const fields = this.registration ? this.registration[acctType] : [];
      const fieldGroups = { required: [] };

      // Sort fields, putting required items first
      if (this.partitionedForms) {
        fieldGroups.optional = [];
        fields.sort((a, b) => b.required - a.required);
        fields.forEach((item) => {
          if (item.required) {
            fieldGroups.required.push(item);
          } else {
            fieldGroups.optional.push(item);
          }
        });
      } else {
        fieldGroups.required = fields;
      }

      return fieldGroups;
    },

    /**
     * Returns if account type is sub
     * @returns {boolean}
     */
    isSubAccount () {
      return this.accountType === ACCOUNT_TYPES.sub;
    },

    /**
     * Returns if DOB field should display.
     * If all of the following are true, field will display:
     * user is a sub account
     * A cookie exists saying the user is under 18, or the /api/user response for legal is false (or not present)
     * Any field marked 'pii' has a value (gender is not 'Not Specified')
     * @returns {boolean}
     */
    canCollectDOB () {
      return this.isSubAccount && this.isAnyPiiDataEntered && ((this.ageGatingCookie && !this.ageGatingCookie.isLegal) || !this.userIsLegal);
    },

    /**
     * Returns if DOB input by user means they are under 18
     * @returns {boolean}
     */
    dobIsUnderAge () {
      return !validateIsOver18(this.fields.dob);
    },

    /**
     * Returns if the /api/user response for legal is true
     * @returns {boolean}
     */
    userIsLegal () {
      return this.legal;
    },

    /**
     * Returns true if user has not input any dob, or if their provided dob
     * means they are under 18 years old
     * @returns {boolean}
     */
    legalValueForCookie () {
      if (this.dobIsUnderAge || !this.fields.dob) {
        return false;
      }
      return true;
    },

    /**
     * Returns user age gating cookie tied to tveUserId
     * @returns {object}
     */
    ageGatingCookie () {
      const cookie = getCookie(`user-age-gating-${this.tveUserId}`);
      return cookie && JSON.parse(cookie);
    },

    /**
     * Returns if user is sub account, has a failed age gating cookie
     * and has previously set pii data (resulting in a pii cookie)
     * @returns {boolean}
     */
    isUnderAgeSubAndHasPiiCookie () {
      return !!(this.isSubAccount && this.ageGatingCookie && !this.ageGatingCookie.isLegal && this.ageGatingCookie.isPiiEntered);
    },

    /**
     * Returns if user has a failed age gating cookie, and currently has
     * pii data entered in the form
     * @returns {boolean}
     */
    isUnderAgeAndHasEnteredPii () {
      return !!(this.ageGatingCookie && !this.ageGatingCookie.isLegal && this.isAnyPiiDataEntered);
    },

    /**
     * Returns true if registering user is allowed to set viewer restrictions
     * @returns {boolean}
     */
    canSetViewerRestrictions () {
      return (this.msoAccountType === MSO_TYPES.primarySub || this.msoAccountType === MSO_TYPES.primaryOnly) && this.accountType === ACCOUNT_TYPES.primary && this.parentalControls === 'SHOWTIME';
    },
  },

  watch: {
    registrationFields: {
      deep: true,
      handler (newValue) {
        for (const fieldGroup in newValue) {
          newValue[fieldGroup].forEach((field) => {
            if (field.id === 'gender')
              this.fields[field.id] = 'Gender';
            else if (field.type === 'text' || field.type === 'email' || field.type === 'dropdown')
              this.fields[field.id] = '';
            else if (field.type === 'checkbox')
              this.fields[field.id] = false;
            this.errors[field.id] = null;
          });
        }
      },
    },

    mso (newVal) {
      this.getMSOData(newVal);
    },
  },

  created () {
    if (this.mso) this.getMSOData(this.mso);
  },

  methods: {
    ...mapActions('mso', ['getMSOData']),

    /**
     * onClose - emit form close event to parent
     */
    onClose () {
      this.isSubmitting = false;
      this.$emit('form-close');
    },

    /**
     * Sets local fieldsWithPii data to input, if the targeted field has pii set to true
     * Calls {@link checkForPiiDataEntry} method to see if any pii data is present.
     * @param {boolean} isPii - if field is has pii:true
     * @param {string} field - form field
     * @param {string} value - input value
     */
    onInput (isPii, field, value) {
      if (isPii) {
        this.fieldsWithPii[`${field}`] = value;
      }
      this.checkForPiiDataEntry();
    },

    /**
     * Resets local data isAnyPiiDataEntered to false
     * Checks if any pii data is entered.
     * If gender is a pii field, but is Not Specified, that does not count as
     * pii data being present (gender must be specified).
     */
    checkForPiiDataEntry () {
      this.isAnyPiiDataEntered = false;
      for (const field in this.fieldsWithPii) {
        if (field !== 'gender' && this.fieldsWithPii[field] !== '') {
          this.isAnyPiiDataEntered = true;
          return;
        } else if (field === 'gender' && ['Not Specified', 'Gender'].indexOf(this.fieldsWithPii[field]) < 0) {
          this.isAnyPiiDataEntered = true;
          return;
        }
      }
    },

    /**
     * Returns component to use in form depending on form field's id
     * @param {string} id - form field id
     * @returns {string}
     */
    formField (id) {
      if (id === 'gender') {
        return 'GenderSelect';
      } else if (id === 'dob') {
        return 'ShoMaskedDate';
      } else if (id === 'ihaveread' || id === 'newsletterSubscribed') {
        return 'ShoCheckbox';
      }
      return 'ShoInput';
    },

    /**
     * Determines if form field should be locked
     * If the field has pii:true, the user is a sub account, a cookie exists saying user is under 18, and has attempted
     * to set pii data before (either from here or during Registration)
     * @param {boolean} isPii - pii property on field
     * @returns {boolean}
     */
    isFieldLocked (isPii) {
      // PII fields should be locked for sub-accounts if cookie exists saying the user is under 18 and has attempted
      // to set pii before (previously from Settings or during Registration)
      return this.isSubAccount && isPii && this.ageGatingCookie && !this.ageGatingCookie.isLegal && this.ageGatingCookie.isPiiEntered;
    },

    /**
     * Determines if field should be displayed (this only really matters for DOB field)
     * If the field id is 'dob', check if dob field can be displayed via the {@link canCollectDOB}
     * Otherwise, returns true
     * @param {string} fieldId - field id
     * @returns {boolena}
     */
    fieldCanDisplay (fieldId) {
      //   show dob field only if the following are true:
      //   the account is a sub-account
      //   the user has never set an age gate cookie/local storage from FFE
      //   - or - the user has set an age gate cookie/local storage and is under 18
      if (fieldId === 'dob') {
        return this.canCollectDOB;
      }
      return true;
    },

    /**
     * Validates form fields, strips pii fields or dob fields depending on
     * whether pii data is present/user is under 18, and sets any necessary cookies
     * Submits payload to savePersonalInfo action in user store, and closes form if successful.
     */
    async onSubmit () {
      this.resetFormError();
      this.isSubmitting = true;
      // if fields are locked, skip valiidation and close form
      if (this.isUnderAgeSubAndHasPiiCookie) {
        this.onClose();
        return;
      }
      if (this.validateFormFields()) {
        // if a cookie exists saying the user is under 18 and has entered pii, close form and don't save
        if (this.isUnderAgeAndHasEnteredPii) {
          this.onClose();
          return;
        }

        // if dob was entered, and user is under 18, strip pii fields
        if (this.isAnyPiiDataEntered && this.fields.dob && this.dobIsUnderAge) {
          this.stripPiiFields();
        }
        // if a cookie exists saying the user is over 18, strip the dob field
        if (this.ageGatingCookie && this.ageGatingCookie.isLegal) {
          this.stripDOBField();
        }

        // format dob correctly for endpoint if it hasn't yet been stripped
        if (this.fields.dob) {
          this.fields.dob = this.fields.dob.replace(/\//g, '');
        }

        if (this.fields.gender === 'Gender')
          delete this.fields.gender;

        try {
          await this.$store.dispatch('user/registerPersonalInfo', this.fields);

          // If there is no cookie present stating the user is over 18, and the account is a subaccount,
          // set a 24-hour cookie tracking:
          // Whether or not any pii fields were filled out in this form
          // Whether or not the user is over 18
          if (!this.ageGatingCookie || (this.ageGatingCookie && !this.ageGatingCookie.isLegal) && this.isSubAccount) {
            const cookieVal = {
              isPiiEntered: this.isAnyPiiDataEntered,
              isLegal: this.legalValueForCookie,
            };
            setCookie(`user-age-gating-${this.tveUserId}`, JSON.stringify(cookieVal), 1);
          }

          this.$emit('form-submitted', (this.isAnyPiiDataEntered && this.dobIsUnderAge));
        } catch (error) {
          if (error.code === 'error.userprofile.usernametaken') {
            this.errors.userName = error.body;
          } else {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
            this.formError = error.body;
          }
        }

        this.isSubmitting = false;
      } else {
        this.isSubmitting = false;
      }
    },

    /**
     * Strips any fields that have pii:true from local fields data,
     * which is used for the payload to update user's personal info
     */
    stripPiiFields () {
      for (const field in this.fieldsWithPii) {
        delete this.fields[field];
      }
    },

    /**
     * Strips DOB field from local fields data and local fieldsWithPii data
     */
    stripDOBField () {
      delete this.fieldsWithPii.dob;
      delete this.fields.dob;
    },

    /**
     * Validates form fields based on valid characters and length,
     * and sets local error specific to that field if error is found.
     * @returns {boolean}
     */
    validateFormFields () {
      this.resetErrors();
      if (this.canCollectDOB && (!this.fields.dob || this.fields.dob === 'mm/dd/yyyy')) {
        this.errors.dob = true;
        return false;
      }
      for (const field in this.fields) {
        const acctType = Object.keys(ACCOUNT_TYPES).find((key) => ACCOUNT_TYPES[key] === this.accountType);
        const registrationFields = this.registration ? this.registration[acctType] : [];
        const registrationField = registrationFields.find((setting) => setting.id === field);

        // if a field is required and is blank, throw up error message
        if ((registrationField.required && this.fields[field] === '')) {
          this.errors[field] = `${registrationField.label} is required`;
        } else if ((registrationField.required && this.fields[field] === false)) {
          this.errors[field] = registrationField.errorMessage;
        } else if (field === 'userName') { // otherwise, validate fields
          const specialCharsRegex = /([~`!#$%^&*+=\-[\]\\';,/{}|\\":<>?])/;
          if ((this.fields.userName && this.fields.userName.indexOf(' ') > 0) || specialCharsRegex.test(this.fields.userName) || this.fields.userName.length < 5) {
            this.errors.userName = registrationField.errorMessage;
          }
        } else if (field === 'emailAddress' && this.fields.emailAddress) {
          if (!validEmail(this.fields.emailAddress)) {
            this.errors.emailAddress = registrationField.errorMessage;
          }
        } else if (field === 'confirmEmail' && this.fields.confirmEmail) {
          if (!validEmail(this.fields.confirmEmail) || this.fields.emailAddress !== this.fields.confirmEmail) {
            this.errors.confirmEmail = registrationField.errorMessage;
          }
        } else if (field === 'firstName') {
          if (this.fields.firstName && this.fields.firstName.length < 2) {
            this.errors.firstName = registrationField.errorMessage;
          }
        } else if (field === 'lastName') {
          if (this.fields.lastName && this.fields.lastName.length < 2) {
            this.errors.lastName = registrationField.errorMessage;
          }
        } else if (field === 'zipcode') {
          const regex = /^\d{5}(?:[-\s]\d{4})?$/;
          if (this.fields.zipcode && !regex.test(this.fields.zipcode)) {
            this.errors.zipcode = registrationField.errorMessage;
          }
        }
      }

      if (Object.values(this.errors).some((error) => (error !== null))) {
        return false;
      }

      return true;
    },

    /**
     * Resets local errors data to null
     */
    resetErrors () {
      for (const property in this.errors) {
        this.errors[property] = null;
      }
    },

    /**
     * Resets local formError data
     */
    resetFormError () {
      this.formError = null;
    },
  },
};
</script>

<style lang="scss" scoped>
  * {
    box-sizing: border-box;
  }

  button {
    margin: 0 0 10px 10px;
    text-align: left;
    width: 300px;
  }

  .back-to-providers {
    cursor: pointer;
    left: 20px;
    position: absolute;
    text-transform: uppercase;
    top: 20px;
  }

  .registration-form {
    display: flex;
    flex-flow: row wrap;
  }

  .form-copy,
  .form-error {
    margin: 0 10px;
    text-align: left;
  }

  .form-error {
    margin-bottom: 10px;
  }

  .field-group-header {
    padding: 0 10px;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
  }

  .form-field {
    display: flex;
    margin-bottom: 20px;
    padding: 0 10px;
    width: 100%;
  }

  :deep(.select) {
    width: 100%;
  }

  :deep(select) {
    height: 50px;
    text-transform: none;
  }

  :deep(.select-container::after) {
    top: 20px;
  }

  .form-legend {
    font-size: 12px;
    margin-left: 10px;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
  }

  .red {
    color: red;
  }

  /**
    Arbitrary switch to vertically centered modal
    - Modals with large vertical heights - STAT registration - may need to have max-heights and overflow scrolling.
    - Switching to vertical centering ignores backdrop vertical padding
    */
  @media (min-width: 768px) and (min-height: 768px) {
    .form-field {
      width: 50%;
    }

    .sho-checkbox-container {
      width: 100%;
    }

    #form-field-required-0 {
      width: 100%;
    }
  }
</style>
