<template>
  <div class="simplePickerPanel">
    <p>Select your provider, sign in and start watching:</p>
    <ul>
      <li
        v-for="(msoEl, index) in simplePickerList"
        :key="`mso-${index}`"
        data-name="mso"
        :data-mso="msoEl.logInText"
        @click="$emit('mso-click', msoEl)"
      >
        <img :src="msoEl.pickerImage.url" :alt="msoEl.logInText">
      </li>
      <li class="showFullPicker" @click="$emit('display-all')">
        See All Providers<span class="chevron-right" />
      </li>
    </ul>
  </div>
</template>

<script>
/**
 * @displayName Simple Picker Panel
 */
export default {
  name: 'SimplePickerPanel',

  emits: ['mso-click', 'display-all'],

  props: {
    /**
     * App dictionary passed as a prop
     */
    dictionary: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  computed: {
    /**
     * simplePickerList - Array of pre-selected MSOs from dictionary
     */
    simplePickerList () {
      if (this.dictionary?.pickerList && this.dictionary.msos) {
        return this.dictionary.pickerList.map((msoName) => this.dictionary.msos.find((mso) => mso.mso === msoName));
      }
      return [];
    },
  },
};
</script>

<style lang="scss" scoped>
  * {
    box-sizing: border-box;
  }

  p {
    @extend %ds-subtitle;
    margin-top: 0;
  }

  ul {
    background: $ds-dark_grey;
    border: 1px solid $ds-dark_grey;
    list-style: none;
    margin: 0;
    max-width: 100%;
    padding: 0;
    width: 540px;
  }

  ul li {
    background: #000;
    border: 1px solid $ds-dark_grey;
    border-top-color: transparent;
    cursor: pointer;
    height: 65px;
    line-height: 60px;
    text-align: center;
    transition: border-color 0.4s;
  }

  .showFullPicker {
    @extend %ds-body-bold;
    border-bottom-color: transparent;
    color: $ds-white;
    line-height: 60px;
  }

  ul li:hover {
    border: 1px solid $ds-white;
  }

  ul li img {
    vertical-align: middle;
  }

  .simplePickerPanel {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .chevron-right {
    background: url("~@assets/img/icons/svg/chevron-right.svg?inline") no-repeat;
    background-size: contain;
    display: inline-block;
    height: 10px;
    left: 8px;
    opacity: 0.7;
    position: relative;
    width: 10px;
  }

  @media (min-width: 667px) {
    ul {
      display: flex;
      flex-wrap: wrap;
    }

    ul li {
      flex: 0 0 50%;
    }

    .showFullPicker {
      flex: 0 0 100%;
    }
  }

  @media (max-height: 740px) {
    #msoModal { /* stylelint-disable-line */
      overflow-y: scroll;
    }
  }
</style>
