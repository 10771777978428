<template>
  <div>
    <ActivationCodeForm v-if="!code && !attemptedActivation" @close="onClose" />
    <SelectDeviceForm 
      v-if="code && registered && !attemptedActivation"
      @submit="onSubmit"
      @close="onClose"
    />
    <ActivationSuccess v-if="isActivationSuccess" :with-registration="withRegistration" @close="onClose" />
    <ActivationFailure
      v-if="activationError"
      :with-registration="withRegistration"
      :device-error="activationError.code === 'error.device.unsupported'"
      @close="onClose"
      @retry="onRetry"
    />
  </div>
</template>

<script>
import ActivationCodeForm from '@components/activation/ActivationCodeForm.vue';
import SelectDeviceForm from '@components/activation/SelectDeviceForm.vue';
import ActivationSuccess from '@components/activation/ActivationSuccess.vue';
import ActivationFailure from '@components/activation/ActivationFailure.vue';
import { mapState, mapGetters } from 'vuex';

/**
 * @displayName STAT Activation
 */
export default {
  name: 'STATActivation',

  components: {
    ActivationCodeForm,
    SelectDeviceForm,
    ActivationSuccess,
    ActivationFailure,
  },

  data () {
    return {
      isActivationSuccess: false,
      activationError: null,
    };
  },

  computed: {
    ...mapState('user', ['registered']),
    ...mapGetters('user', ['isLoggedIn']),
    ...mapState('deviceActivation', ['code']),
    ...mapState('statActivation', ['withRegistration']),

    /**
     * Return true if user has submitted a code and device
     * @returns {boolean}
     */
    attemptedActivation () {
      return this.activationError || this.isActivationSuccess;
    },
  },

  watch: {
    code (newVal) {
      // Trigger login flow if user is not logged in
      if (newVal && !this.isLoggedIn) {
        this.$store.dispatch('msoPicker/show');
      }
    },
  },

  methods: {
    /**
     * Submit activation code to API and handle result
     */
    async onSubmit () {
      this.activating = true;
      try {
        await this.$store.dispatch('deviceActivation/activate');
        this.isActivationSuccess = true;
      } catch (error) {
        if (error?.code === 'error.device.deviceLimit.LID') {
          this.$store.dispatch('statActivation/close');
          this.$store.dispatch('modal/show', error);
        }
        this.isActivationSuccess = false;
        this.activationError = error;
      }
      this.activating = false;
    },
    /**
     * Clear out activation code and exit activation flow
     */
    onClose () {
      this.$store.dispatch('deviceActivation/reset');
      this.$store.dispatch('statActivation/close');
    },
    /**
     * Clear out activation code and restart activation flow
     */
    onRetry () {
      this.$store.dispatch('deviceActivation/reset');
      this.resetData();
    },
    /**
     * Clear out activation result and error code
     */
    resetData () {
      this.isActivationSuccess = false;
      this.activationError = null;
    },
  },
};
</script>
