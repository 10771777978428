<template>
  <ShoModal
    title="Under 18"
    body="We will not retain your email address, zip code, first name, last name or gender. This information has been deleted. You will not receive newsletters."
    :buttons="[{ label: 'continue', },]"
    @close="onClose"
  />
</template>

<script>
import ShoModal from '@components/ui/ShoModal.vue';

/**
 * @displayName PIINotificationModal
 */
export default {
  name: 'PIINotificationModal',

  components: {
    ShoModal,
  },

  emits: ['close'],

  methods: {
    /**
     * onClose - emit close event to parent
     */
    onClose () {
      this.$emit('close');
    },
  },
};
</script>
