<template>
  <div>
    <h2 class="title">It appears that your Dish<sup>&reg;</sup> Subscription does not include Showtime <sup>&reg;</sup></h2>
    <p>
      Showtime Anytime<sup>&reg;</sup> is available free to DISH customers who currently subscribe to Showtime. If you
      believe that you received this message in error, <a class="sho-link" target="_blank" href="http://www.dishnetwork.com/customerservice/contactus/live_chat/default.aspx?chat=Donline">CHAT NOW</a> with a DISH agent.
    </p>
    <p>
      To subscribe to Showtime and start using Showtime Anytime, please <a href="https://customersupport.dishnetwork.com/customercare/programming/prepChangeProgramming.do" target="_blank" class="sho-link">click here</a>
    </p>
  </div>
</template>
