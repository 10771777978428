<template>
  <div>
    <h2 class="title">It appears that your Cox Subscription does not include Showtime <sup>&reg;</sup></h2>
    <p>
      Showtime Anytime<sup>&reg;</sup> is available free to all Cox cable customers who currently subscribe to Showtime. If you
      would like to sign up for Showtime, you can <a href="https://store.cox.com/residential-store/shop.cox?pc=coxshowupg&sc_id=cr_z_z_premiums_SHO_14Q2" class="sho-link" target="_blank">order now;</a>
      otherwise, if you believe you received this in error, you can <a href="http://ww2.cox.com/myconnection/home.cox" class="sho-link" target="_blank">log into your account</a> to verify
      your subscription to Showtime.
    </p>
  </div>
</template>
