const COOKIE_NAMESPACE = 'sho:';
/**
 * retrieves cookie value for a given cookie name.
 * @param {name} - name of cookie
 * @returns {string, integer}
 */
export const getCookie = function (name) {
  const cookie = {};
  document.cookie.split(';').forEach((item) => {
    const [key,value] = item.split('=');
    cookie[key.trim()] = value;
  });
  return cookie[`${COOKIE_NAMESPACE}${name}`];
};

/**
 * sets cookie, given a name, value, and number of days in which it will expire
 * @param {name} - name of cookie
 * @param {value} - cookie value
 * @param {numberOfDays} - number of days after which cookie will expire
 */
export const setCookie = function (name, value, numberOfDays) {
  const date = new Date();
  date.setTime(+date + (numberOfDays * 86400000));
  document.cookie = `${COOKIE_NAMESPACE}${name}=${value}; expires=${date.toUTCString()}`;
};
