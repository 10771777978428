const About = () => import('@pages/stat/AboutPage.vue');
const FreeFullEpisodes = () => import('@pages/stat/FreeFullEpisodesPage.vue');
const Settings = () => import('@pages/stat/SettingsPage.vue');
const MSOLogin = () => import('@components/auth/MSOLogin.vue');
const HandleLoginPage = () => import('@pages/HandleLoginPage.vue');

// Home wrapper contains both auth and mobile device logic
const HomeWrapper = () => import('@pages/HomeWrapper.vue');

import baseRoutes from '@routes/shared/base-routes.js';
import contentRoutes from '@routes/shared/content-routes.js';
import legalRoutes from '@routes/shared/legal-routes.js';
import legacyRoutes from '@routes/shared/legacy-routes.js';

import store from '@store/store.js';

export default [
  ...baseRoutes,
  ...legacyRoutes,
  ...contentRoutes,
  ...legalRoutes,

  /**
   * Home - stat home page.
   */
  {
    path: '/',
    name: 'home',
    component: HomeWrapper,
    props: { page: 'home' },
    meta: {
      section: 'home',
    },
  },

  /**
   * Requests the home page and then opens MSO Picker.
   */
  {
    path: '/login',
    name: 'login',
    component: HomeWrapper,
    props: { page: 'home' },
    beforeEnter: () => {
      store.dispatch('msoPicker/show');
      return true;
    },
    meta: {
      section: 'home',
      access: {
        stat: {
          authenticated: false,
          unauthenticated: true,
          mobile: true,
        },
      },
    },
  },

  /**
   * Start the activation flow, either on home or settings
   * based on user authz state
   */
  {
    path: '/activate',
    name: 'activate',
    component: HomeWrapper,
    beforeEnter: () => {
      store.dispatch('statActivation/show');
      if (store.state.user.isAuthorized) {
        return { name: 'settings' };
      } else {
        return true;
      }
    },
    meta: {
      section: 'home',
    },
  },

  /**
   * Route user is directed to after MSO login
   */
  {
    path: '/handleLogin',
    name: 'handleLogin',
    component: HandleLoginPage,
  },

  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      section: 'settings',
      access: {
        stat: {
          authenticated: true,
          unauthenticated: false,
          mobile: true,
        },
      },
    },
  },
  {
    path: '/freeepisodes',
    name: 'freeFullEpisodes',
    component: FreeFullEpisodes,
    meta: {
      access: {
        stat: {
          authenticated: false,
          unauthenticated: true,
          mobile: false,
        },
      },
    },
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      access: {
        stat: {
          authenticated: true,
          unauthenticated: true,
          mobile: true,
        },
      },
    },
  },
  {
    path: '/tvp/:msoId',
    name: 'msoLogin',
    component: MSOLogin,
    props: true,
  },

  /**
   * Help Center redirect
   */
  {
    path: '/help',
    alias: '/faq',
    beforeEnter: () => {
      const dictionary = store.state?.dictionary;
      const helpCenterUrl = dictionary?.customerService?.helpCenter || 'https://help.showtimeanytime.com/';
      window.location.replace(helpCenterUrl);
    },
  },
];
