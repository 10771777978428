<template>
  <ShoModal v-if="dictionary && dictionary.msos" title="Sign In" @close="onCloseMSOPicker">
    <SimplePickerPanel
      v-if="!displayAll"
      :dictionary="dictionary"
      @display-all="onDisplayAll"
      @mso-click="onMSOClick"
    />
    <FullPickerPanel
      v-else-if="displayAll"
      :dictionary="dictionary"
      @mso-click="onMSOClick"
    />
  </ShoModal>
</template>

<script>
import ShoModal from '@components/ui/ShoModal.vue';
import FullPickerPanel from '@components/ui/stat/FullPickerPanel.vue';
import SimplePickerPanel from '@components/ui/stat/SimplePickerPanel.vue';

import { mapState } from 'vuex';

/**
 * @displayName MSO Picker
 */
export default {
  name: 'MSOPicker',

  components: {
    ShoModal,
    FullPickerPanel,
    SimplePickerPanel,
  },

  data () {
    return {
      displayAll: false,
    };
  },

  computed: {
    ...mapState(['dictionary']),
  },

  methods: {
    /**
     * onCloseMSOPicker - Closes MSO Picker modal and clear activation flow
     */
    onCloseMSOPicker (redirectingToLogin = false) {
      this.displayAll = false;
      this.$store.dispatch('statActivation/close');
      this.$store.dispatch('msoPicker/close');

      if (!redirectingToLogin) {
        this.$store.dispatch('deviceActivation/reset');
      }
    },

    /**
     * onDisplayAll - Display to the user the full list of providers
     */
    onDisplayAll () {
      this.displayAll = true;
    },

    /**
     * onMSOClick
     * @param {object} msoEl - user chosen MSO
     * Kicks off MSO login process given chosen MSO
     */
    onMSOClick (msoEl) {
      this.onCloseMSOPicker(true);
      window.location.href = `${msoEl.authURL}&returnpage=${window.location.href}`;
    },
  },
};
</script>

<style lang="scss" scoped>
  * {
    box-sizing: border-box;
  }

  .back-to-providers {
    cursor: pointer;
    left: 20px;
    position: absolute;
    text-transform: uppercase;
    top: 20px;
  }

  .has-title .contains-iframe {
    padding: 30px 0 0;
  }
</style>
