<template>
  <div>
    <ParentalControlsPromptModal 
      v-if="showPrompt"
      :is-sub-account-mso="isSubAccountMSO"
      @toggle-form="toggleForm($event)"
      @close="onClose"
    />

    <ParentalControlsFormModal 
      v-if="showForm"
      :is-sub-account-mso="isSubAccountMSO"
      :user-account-type="accountType"
      @submit="onSubmit"
      @close="onClose"
    />

    <ShoModal v-if="error" :title="error.title" :body="error.body" @close="onErrorModalClose" />
  </div>
</template>

<script>
import ParentalControlsPromptModal from '@components/registration/parental-controls/ParentalControlsPromptModal.vue';
import ParentalControlsFormModal from '@components/registration/parental-controls/ParentalControlsFormModal.vue';
import ShoModal from '@components/ui/ShoModal.vue';

import { mapActions, mapState } from 'vuex';
import { MSO_TYPES } from '@utils/constants.js';


/**
 * @displayName Parental Controls Modal
 * This is the parent modal for parental controls Prompt and Form modals
 */
export default {
  name: 'ParentalControlsModal',

  components: {
    ParentalControlsPromptModal,
    ParentalControlsFormModal,
    ShoModal,
  },

  emits: ['close'],

  data () {
    return {
      showPrompt: true,
      showForm: false,
      error: null,
    };
  },

  computed: {
    ...mapState('mso', { msoAccountType: 'accountType' }),
    ...mapState('user', ['accountType']),
    /**
     * Indicates if the user's MSO has sub-accounts.
     * @returns {Boolean}
     */
    isSubAccountMSO () {
      return this.msoAccountType === MSO_TYPES.primarySub;
    },
  },

  methods: {
    ...mapActions('viewerRestrictions', ['setInitialViewerRestrictions']),

    // Allows this component to handle the logic of switching from prompt component to form component.
    toggleForm () {
      this.showPrompt = false;
      this.showForm = true;
    },

    // Submit the settings selected from child component (form) to api.
    async onSubmit (settings) { 
      try {
        await this.setInitialViewerRestrictions(settings);
        this.onClose();
      } catch (error) {
        this.error = error;
      }
    },

    onErrorModalClose () {
      this.error = null;
    },

    onClose () {
      this.showPrompt = false;
      this.showForm = false;
      this.$emit('close');
    },
  },
};


</script>
