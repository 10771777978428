<template>
  <form
    class="sho-form"
    :method="method"
    @submit.prevent="$emit('submit', $event)"
  >
    <slot />
  </form>
</template>

<script>
/**
 * Styled form wrapper component.
 *
 * @fires submit
 */
export default {
  name: 'ShoForm',
  props: {
    /**
     * Populates the form element method prop.
     */
    method: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ['submit'],
};
</script>

<style lang="scss" scoped>
  /**
    Grids start as a 2-column grid, and above 768px switch to a 6 column grid.
    */
  :deep(.control-group) {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 16px;
  }

  :deep(.control-col-1),
  :deep(.control-col-2),
  :deep(.control-col-3) {
    grid-column: span 1;
  }

  :deep(.control-col-4),
  :deep(.control-col-5),
  :deep(.control-col-6) {
    grid-column: span 2;
  }

  :deep(.form-actions) .sho-button {
    grid-column: span 2;
    margin-top: 24px;
    width: 100%;
  }

  :deep(.form-actions) p {
    @extend %ds-caption-2;
  }

  /* override roadblock's link styling */
  :deep(a.dismiss) {
    @extend %ds-caption-1;
    color: $ds-medium_grey;
    display: block;
    margin: 10px auto;
    text-align: center;
    width: 100%;
  }

  /* single-column overrides */
  :deep(.form-actions.single-col) {
    max-width: 100%;
  }

  :deep(.form-actions.single-col) > * {
    margin: 24px auto;
    width: 100%;
  }

  /* Form Typography */
  :deep(.section-title) {
    @extend %ds-callout-2;
    line-height: 1;
    margin: 0 0 4px;
    text-align: left;
  }

  :deep(p.error),
  :deep(.error) a {
    color: red;
    font-family: inherit;
    font-size: inherit;
  }

  :deep(.error) label a {
    color: inherit;
  }

  :deep(.legal) {
    @extend %ds-caption-2;
  }

  /* TODO: See if it's safe to combine these styles, legal and disclaimer */

  :deep(.legal-disclaimer) {
    @extend %ds-caption-1;
    margin-top: 24px;
    text-align: left;
  }

  :deep(.legal-disclaimer) a {
    color: black;
  }

  /* Matches ShoInput label styles */
  :deep(.required-indicator) {
    @extend %ds-caption-1;
    margin: 2em 0 0;
    text-align: center;
  }

  :deep(.required-indicator::before) {
    color: red;
    content: "* ";
  }

  /**
   * TODO: Move some form-section utility styles here -
   * * .section-cta (EDIT, CANCEL buttons)
   */

  :deep(.form-sections) section,
  :deep(.form-sections) .section {
    border-bottom: 1px solid rgba($ds-medium_grey, 40%);
    padding: 1.25em 0;
    position: relative;
  }

  :deep(.form-sections) section:first-child,
  :deep(.form-sections) .section:first-child {
    padding-top: 0;
  }

  :deep(.form-sections) section:last-child,
  :deep(.form-sections) .section:last-child {
    border-bottom: none;
  }

  :deep(.form-sections) .section-cta {
    appearance: none;
    background: none;
    border: 0;
    line-height: 1;
    padding: 0;
    position: absolute;
    right: 0;
    text-decoration: underline;
    top: 1.25em;
  }

  @media (min-width: 768px) {
    :deep(.control-group) {
      grid-template-columns: repeat(6, 1fr);
      margin-bottom: 20px;
    }

    :deep(.control-col-1) {
      grid-column: span 1;
    }

    :deep(.control-col-2) {
      grid-column: span 2;
    }

    :deep(.control-col-3) {
      grid-column: span 3;
    }

    :deep(.control-col-4) {
      grid-column: span 4;
    }

    :deep(.control-col-5) {
      grid-column: span 5;
    }

    :deep(.control-col-6) {
      grid-column: span 6;
    }

    :deep(.form-actions) {
      display: flex;
      justify-content: space-evenly;
      margin-top: 24px;
    }

    :deep(.form-actions) .sho-button {
      display: block;
      flex: 1;
      margin: 0 5px;

      /* NOTE: Masking this for now; will re-address with payment page updates */

      /* max-width: 360px /* Shared with rb-small form width */
    }

    :deep(.form-actions) .sho-button + .sho-button {
      margin-top: 0;
    }

    :deep(.form-actions) .sho-button:first-child {
      margin-left: 0;
    }

    :deep(.form-actions) .sho-button:last-child {
      margin-right: 0;
    }

    /* single-column overrides */
    :deep(.form-actions.single-col) {
      display: block;
      margin: 0 auto;
      max-width: 367px;
    }

    :deep(.form-actions.single-col) > * {
      display: block;
      margin: 24px auto;
      max-width: 100%;
    }
  } /* End, max-width 768px */
</style>
