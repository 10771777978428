<template>
  <ShoModal
    :title="modalTitle"
    @close="onClose"
  >

    <!-- Failure during registration -->
    <div v-if="withRegistration" class="failure-modal-body">
      <div class="image-container">
        <img :src="devicesImage" class="logo" alt="Supported devices include smart phones, tablets, smart tvs, laptops, and PCs.">
      </div>

      <!-- Device Error -->
      <template v-if="deviceError">
        <div class="text-container">
          <p>You are now registered for Showtime Anytime, but the device you tried to activate is not supported.</p>
        </div>
        <div class="buttons buttons-1">
          <ShoButton variant="primary" @click="$emit('close')">OK</ShoButton>
        </div>
      </template>

      <!-- Non-Device Error -->
      <template v-else>
        <div class="text-container">
          <p>You are now registered for Showtime Anytime, but there seems to be a problem with your activation code.</p>
          <p>Please get a new code and try again.</p>
        </div>
        <div class="buttons buttons-2">
          <ShoButton @click="$emit('close')">NOT NOW</ShoButton>
          <ShoButton variant="primary" @click="$emit('retry')">RETRY ACTIVATION</ShoButton>
        </div>
      </template>
    </div>

    <!-- Failure NOT during registration -->
    <div v-else>

      <!-- Device Error -->
      <template v-if="deviceError">
        <p>Sorry, the device you tried to activate is not currently supported.</p>
        <div class="buttons buttons-1">
          <ShoButton variant="primary" @click="$emit('close')">OK</ShoButton>
        </div>
      </template>

      <!-- Non-Device Error -->
      <template v-else>
        <p>There seems to be a problem with your activation code.</p>
        <p>Please get a new code and try again.</p>
        <div class="buttons buttons-2">
          <ShoButton @click="$emit('close')">NOT NOW</ShoButton>
          <ShoButton variant="primary" @click="$emit('retry')">RETRY ACTIVATION</ShoButton>
        </div>
      </template>
    </div>

  </ShoModal>
</template>

<script>
import ShoModal from '@components/ui/ShoModal.vue';
import ShoButton from '@components/ui/ShoButton.vue';

/**
 * @displayName ActivationFailure
 */
export default {
  name: 'ActivationFailure',

  components: {
    ShoModal,
    ShoButton,
  },

  emits: ['close', 'retry'],

  props: {
    /**
     * Indicates if user registered during activation flow
     */
    withRegistration: {
      type: Boolean,
      required: true,
    },
    /**
     * Indicates if activation failed due to unsupported device.
     */
    deviceError: {
      type: Boolean,
      required: true,
    },
  },

  data () {
    return {
      devicesImage: `${__BASE_IMAGE_URL__}/frontdoor/stat_aboutlockup_720.png`,
    };
  },

  computed: {
    modalTitle () {
      return this.withRegistration ? 'WELCOME TO SHOWTIME ANYTIME' : 'SOMETHING WENT WRONG';
    },
  },

  methods: {
    /**
     * onClose - emit close event to parent
     */
    onClose () {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  .image-container {
    display: none;
  }

  .buttons {
    width: 100%;
  }

  @media (min-width: 768px) {
    .failure-modal-body {
      display: flex;
      flex-wrap: wrap;
    }

    .image-container {
      display: block;
      flex: 1 0 0;
    }

    .image-container img {
      max-width: 100%;
    }

    .text-container {
      flex: 1 0 0;
      margin-left: 10px;
      text-align: left;
    }
  }
</style>
