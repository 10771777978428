<template>
  <div class="select sho-select form-control" :class="componentClass">
    <label v-if="label && !animated" :for="name">{{ label }}</label>

    <div class="select-container">
      <select
        :id="name"
        :name="name"
        :aria-describedby="`error-${name} helper-${name}`"
        :aria-invalid="!!errorMessage"
        :aria-required="required"
        :disabled="disabled"
        :tabindex="tabindex"
        :data-recurly="recurlyKey"
        :value="modelValue || value"
        @change="e => $emit('update:modelValue', e.target.value)"
      >
        <option
          v-for="(option, index) in parsedOptions"
          :key="`${name}-option-${index}`"
          :value="option.value"
          :disabled="isNullValue(option.value)"
        >
          {{ option.text }}
        </option>
      </select>

      <ShoIcon type="triangle-down" height="6px" class="triangle" />

      <label v-if="label && animated" :for="name">{{ label }}</label>
    </div>

    <p v-if="errorMessage" :id="`error-${name}`" role="alert" data-name="field-error" :data-field-id="name" class="error-msg">{{ errorMessage }}</p>
    <p v-if="helper" :id="`helper-${name}`" class="helper">{{ helper }}</p>
  </div>
</template>

<script>
import ShoIcon from '@components/ui/ShoIcon.vue';

export default {
  name: 'ShoSelect',
  components: { ShoIcon },
  emits: ['update:modelValue'],
  props: {
    /**
     * Select name and id attrs.
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * Options array in ['opt1', 'opt2', 'opt3'] format,
     * or [{ text: 'Label', value: 123 }] format.
     */
    options: {
      type: Array,
      required: true,
    },
    /**
     * Used for 2-way binding via v-model.
     * Both modelValue and value should never be used together.
     */
    modelValue: {
      type: [String, Number, Boolean],
      default: null,
    },
    /**
     * Used for setting the input value via a 'native' value attr.
     * Both modelValue and value should never be used together.
     */
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
    /**
     * Input label.
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Required input indicator.
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Disabled input indicator.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Tabindex attr.
     */
    tabindex: {
      type: Number,
      default: null,
    },
    /**
     * Recurly id for input.
     */
    recurlyKey: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Input helper text.
     */
    helper: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Field error indicator, either boolean or error string.
     */
    error: {
      type: [Boolean, String],
      default: false,
    },
    /**
     * Indicates the select should be styled to match animated-label text inputs.
     */
    animated: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    componentClass () {
      return {
        required: this.required,
        error: this.hasError,
        animated: this.animated,
        empty: !this.value && !this.modelValue,
      };
    },
    parsedOptions () {
      return this.options.map((option) => {
        if (typeof option === 'string') {
          return { text: option, value: option };
        }
        return option;
      });
    },
    hasError () {
      if (typeof this.error === 'string') {
        return !!this.error.length;
      }
      return this.error || false;
    },
    errorMessage () {
      if (this.error && typeof this.error === 'string' && this.error.length) {
        return this.error;
      }
      return null;
    },
  },
  methods: {
    isNullValue (value) {
      return typeof value === 'undefined' || value === null;
    },
  },
};
</script>

<style lang="scss" scoped>
  .sho-select {
    position: relative;
    text-align: left;
  }

  .select-container {
    position: relative;

    .triangle {
      color: $ds-medium_grey;
      pointer-events: none;
      position: absolute;
      right: 13px;
      top: 17px;
    }
  }

  select {
    @extend %ds-input;
    appearance: none;
    background: white;
    border: 1px solid $ds-black;
    border-radius: 0;
    box-sizing: border-box;
    color: $ds-black;
    height: 40px;
    position: relative;
    text-indent: 10px;
    width: 100%;
  }

  select:focus {
    border-color: $ds-medium_grey;
    outline: none;
  }

  select:disabled {
    /* TODO: Add disabled icon */
    background-color: white;
    color: $ds-xtra_light_grey;
    opacity: 1;
  }

  label {
    @extend %ds-caption-2;
    display: inline-block;
    padding-bottom: 5px;
    z-index: 2;
  }

  .required label::before {
    color: red;
    content: "* ";
  }

  .helper {
    @extend %ds-caption-2;
    text-align: left;
  }

  .helper:last-child {
    margin-bottom: 0;
  }

  .error select,
  .error select:focus,
  .error .option-list {
    border-color: red;
  }

  .error label,
  .error-msg {
    color: red;
  }

  .error-msg {
    @extend %ds-caption-2;
    margin: 5px 0 0;
  }

  /* Style Variants */

  /* Dark Form Control */
  .dark .select-container .triangle {
    color: $ds-sho_neutral_light;
  }

  .dark select {
    background: $ds-charcoal_grey;
    border-color: $ds-charcoal_grey;
    color: white;
  }

  .dark select:focus,
  .dark select:hover {
    border-color: $ds-dark_grey;
  }

  .dark.error select,
  .dark.error select:focus {
    border-color: red;
  }

  /* 'Animated' Input Styles */
  .animated select {
    height: 50px;
    padding-top: 20px;
  }

  .animated label {
    @extend %ds-input-placeholder-sm;
    color: black;
    left: 10px;
    pointer-events: none;
    position: absolute;
    top: 5px;
    transition: all 0.2s ease;
  }

  .animated .select-container::after {
    top: 20px;
  }

  .animated.empty label {
    @extend %ds-input-placeholder;
    text-transform: none;
    top: 18px;
  }
</style>
