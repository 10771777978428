<template>
  <ShoModal
    title="Parental Controls"
    @close="onClose"
  >
    <div id="body-text">
      <p v-if="isSubAccountMso">Parental Controls allow you to restrict content for your account and any of your sub-accounts.</p>
      <p v-else>Parental Controls allow you to restrict content for your account.</p>
      <p>Do you want to set parental controls now? You can also set parental controls at any time on the Settings page.</p>
    </div>
      
    <div class="buttons">
      <ShoButton @click="onClose">No Thanks</ShoButton> <!-- TODO:  Needs to direct user to next step in registration. (see STAT-36611) -->
      <ShoButton variant="primary" @click="toggleForm">Ok</ShoButton>
    </div>
  </ShoModal>
</template>

<script>
import ShoModal from '@components/ui/ShoModal.vue';
import ShoButton from '@components/ui/ShoButton.vue';


/**
 * @displayName Parental Controls Prompt Modal
 */
export default {
  name: 'ParentalControlsPromptModal',

  components: {
    ShoModal,
    ShoButton,
  },

  emits: ['close', 'toggle-form'],

  // A computed boolean value from parent component that shows if mso account type has sub's or not.
  props: {
    isSubAccountMso: {
      type: Boolean,
      default: false,
    },
  },

  methods: {

    // Bubbles up the event that user is done with prompt component, and should be shown the form component.
    toggleForm () {
      this.$emit('toggle-form');
    },

    onClose () {
      this.$emit('close');
    },
  },
};

</script>

<style lang="scss" scoped>
p {
  padding-bottom: 2%;
}

#body-text {
  text-align: left;
}

</style>
