<template>
  <div>
    <h2 class="title">It appears that your DIRECTV<sup>&reg;</sup> subscription does not include Showtime <sup>&reg;</sup></h2>
    <p>
      Showtime Anytime<sup>&reg;</sup> is available free to all DIRECTV customers who currently subscribe to Showtime. If you
      believe you received this in error, please contact DIRECTV at
      <a href="tel://18005315000">1-800-531-5000</a> or visit
      <a class="sho-link" target="_blank" href="http://www.directv.com">http://www.directv.com</a>
    </p>
    <p>
      If you would like to sign up for Showtime, you can do so by visiting <a class="sho-link" target="_blank" href="http://www.directv.com/showtime">http://www.directv.com/showtime</a> or by calling <a href="tel://18005315000">1-800-531-5000</a>.
    </p>
  </div>
</template>
