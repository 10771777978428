<template>
  <div class="field">
    <label :for="name">{{ label }}</label>
    <ShoSelect
      class="dark"
      :name="name"
      :options="RESTRICTIONS[type]"
      :value="localValue"
      :disabled="disabled"
      @change="$emit('update:modelValue', $event.target.value)"
    />
  </div>
</template>

<script>
import ShoSelect from '@components/ui/ShoSelect.vue';

const RESTRICTIONS = {
  movie: [
    { text: 'G', value: 'MOVIE_G' },
    { text: 'PG', value: 'MOVIE_PG' },
    { text: 'PG-13', value: 'MOVIE_PG_13' },
    { text: 'R', value: 'MOVIE_R' },
    { text: 'Unrestricted', value: 'MOVIE_NR' },
  ],
  tv: [
    { text: 'TV-Y', value: 'TV_Y' },
    { text: 'TV-Y7', value: 'TV_Y7' },
    { text: 'TV-G', value: 'TV_G' },
    { text: 'TV-PG', value: 'TV_PG' },
    { text: 'TV-14', value: 'TV_14' },
    { text: 'TV-MA', value: 'TV_MA' },
    { text: 'Unrestricted', value: 'TV_NR' },
  ],
};

export default {
  name: 'RestrictionSelector',
  emits: ['update:modelValue'],
  components: { ShoSelect }, 
  props: {
    /**
     * Input id and name attrs.
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * Used for 2-way binding via v-model.
     * Both modelValue and value should never be used together.
     */
    modelValue: {
      type: String,
      default: null,
    },
    /**
     * Used for setting the input value via a 'native' value attr.
     * Both modelValue and value should never be used together.
     */
    value: {
      type: String,
      default: null,
    },
    /**
     * Indicates if the rating selector is for movie or tv ratings.
     */
    type: {
      type: String,
      required: true,
      validator (value) {
        return ['movie', 'tv'].indexOf(value) !== -1;
      },
    },
    /**
     * Indicates the input should be disabled.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      RESTRICTIONS,
      localValue: this.modelValue || this.value,
    };
  },
  computed: {
    /**
     * Generates an input label based on selector type.
     * @returns {String}
     */
    label () {
      return this.type === 'movie' ? 'Movies' : 'TV';
    },
  },
};

</script>

<style lang="scss" scoped>
  .select {
    width: 140px;
  }

  .field {
    color: $ds-sho_neutral_light;
    display: flex;
    flex-direction: column;
    margin-right: 16px;
  }

  label {
    display: block;
    margin-bottom: 6px;
  }
</style>
