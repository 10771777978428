<template>
  <ShoModal
    class="upsell-header"
    title-image-path="/assets/img/stat/icons/svg/showtime-logo.svg"
    @close="onCloseModal"
  >
    <div class="upsell-modal-body">
      <div class="upsell-modal-section">
        <img :src="devicesLockupImage" class="logo" alt="Supported devices include smart phones, tablets, smart tvs, laptops, and PCs.">
      </div>
      <div class="upsell-modal-section text-section">
        <ATTUpsell v-if="template && template.toLowerCase() === 'att'" />
        <BlueridgeUpsell v-else-if="template && template.toLowerCase() === 'blueridge'" />
        <CablevisionUpsell v-else-if="template && template.toLowerCase() === 'cablevision'" />
        <CenturyLinkUpsell v-else-if="template && template.toLowerCase() === 'centurylink'" />
        <CharterUpsell v-else-if="template && template.toLowerCase() === 'charter'" />
        <CoxUpsell v-else-if="template && template.toLowerCase() === 'cox'" />
        <DirecTVUpsell v-else-if="template && template.toLowerCase() === 'directv'" />
        <DishUpsell v-else-if="template && template.toLowerCase() === 'dish'" />
        <DTVNowUpsell v-else-if="template && template.toLowerCase() === 'dtvnow'" />
        <DTVPRUpsell v-else-if="template && template.toLowerCase() === 'dtvpr'" />
        <GrandeUpsell v-else-if="template && template.toLowerCase() === 'grande'" />
        <MediacomUpsell v-else-if="template && template.toLowerCase() === 'mediacom'" />
        <TWCUpsell v-else-if="template && template.toLowerCase() === 'twc'" />
        <template v-else>
          <p>{{ body }}</p>
        </template>
        <ShoButton
          variant="primary"
          @click="onCloseModal"
        >Ok</ShoButton>
      </div>
    </div>
  </ShoModal>
</template>
<script>
import ATTUpsell from '@components/ui/stat/upsell-templates/ATTUpsell.vue';
import BlueridgeUpsell from '@components/ui/stat/upsell-templates/BlueridgeUpsell.vue';
import CablevisionUpsell from '@components/ui/stat/upsell-templates/CablevisionUpsell.vue';
import CenturyLinkUpsell from '@components/ui/stat/upsell-templates/CenturyLinkUpsell.vue';
import CharterUpsell from '@components/ui/stat/upsell-templates/CharterUpsell.vue';
import CoxUpsell from '@components/ui/stat/upsell-templates/CoxUpsell.vue';
import DirecTVUpsell from '@components/ui/stat/upsell-templates/DirecTVUpsell.vue';
import DishUpsell from '@components/ui/stat/upsell-templates/DishUpsell.vue';
import DTVNowUpsell from '@components/ui/stat/upsell-templates/DTVNowUpsell.vue';
import DTVPRUpsell from '@components/ui/stat/upsell-templates/DTVPRUpsell.vue';
import GrandeUpsell from '@components/ui/stat/upsell-templates/GrandeUpsell.vue';
import MediacomUpsell from '@components/ui/stat/upsell-templates/MediacomUpsell.vue';
import TWCUpsell from '@components/ui/stat/upsell-templates/TWCUpsell.vue';

import ShoButton from '@components/ui/ShoButton.vue';
import ShoModal from '@components/ui/ShoModal.vue';

import { mapState } from 'vuex';

/**
 * @displayName Upsell Modal
 */
export default {
  name: 'UpsellModal',

  components: {
    ATTUpsell,
    BlueridgeUpsell,
    CablevisionUpsell,
    CenturyLinkUpsell,
    CharterUpsell,
    CoxUpsell,
    DirecTVUpsell,
    DishUpsell,
    DTVNowUpsell,
    DTVPRUpsell,
    GrandeUpsell,
    MediacomUpsell,
    TWCUpsell,
    ShoButton,
    ShoModal,
  },
  data () {
    return {
      devicesLockupImage: `${__BASE_IMAGE_URL__}/frontdoor/stat_aboutlockup_720.png`,
    };
  },

  computed: {
    ...mapState('upsellModal', ['body', 'template']),
  },

  methods: {
    onCloseModal () {
      this.$store.dispatch('upsellModal/close');
    },
  },
};
</script>
<style lang="scss" scoped>
  img {
    width: 100%;
  }

  p {
    color: $ds-white;
  }

  .upsell-modal-body {
    display: flex;
    flex-direction: column;
  }

  .upsell-modal-section {
    flex-basis: 0;
    flex-grow: 1;
  }

  .text-section {
    margin-left: 10px;
    text-align: left;
  }

  :deep(.modal-title) {
    border-bottom: none;
    padding-left: 20px;
    padding-top: 15px;
    text-align: left;
  }

  :deep(.title) {
    @extend %ds-headline;
    margin-top: 0;
  }

  :deep(a) {
    color: $ds-sho_primary;
  }

  @media (min-width: 768px) {
    .upsell-modal-body {
      flex-direction: row;
    }
  }
</style>
