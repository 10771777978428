<template>
  <ShoModal
    :title="modalTitle"
    :body="modalBody" 
    @close="onClose"
  >
    <div v-if="withRegistration" class="success-modal-body">
      <div class="image-container">
        <img :src="devicesImage" class="logo" alt="Supported devices include smart phones, tablets, smart tvs, laptops, and PCs.">
      </div>
      <div class="text-container">
        <p>You are now registered for Showtime Anytime and have activated your device.</p>
        <p>You now have unlimited access to a huge selection of programming on your TV, tablet, phone, and computer.</p>
        <MobileAppButton v-if="isSupportedMobileDevice" />
      </div>
    </div>
  </ShoModal>
</template>

<script>
import ShoModal from '@components/ui/ShoModal.vue';
import MobileAppButton from '@components/ui/stat/MobileAppButton.vue';
import { isSupportedMobileDevice } from '@utils/mobile-detect.js';

/**
 * @displayName ActivationSuccess
 */
export default {
  name: 'ActivationSuccess',

  components: {
    ShoModal,
    MobileAppButton,
  },

  emits: ['close'],

  props: {
    withRegistration: {
      type: Boolean,
      required: true,
    },
  },

  data () {
    return {
      devicesImage: `${__BASE_IMAGE_URL__}/frontdoor/stat_aboutlockup_720.png`,
    };
  },

  computed: {
    modalTitle () {
      return this.withRegistration ? 'WELCOME TO SHOWTIME ANYTIME' : 'SUCCESS';
    },
    modalBody () {
      return this.withRegistration ? null : 'You have activated your device.';
    },
    /**
     * Indicates if user is on supported mobile device
     * @returns {boolean}
     */
    isSupportedMobileDevice () {
      return isSupportedMobileDevice();
    },
  },

  methods: {
    /**
     * onClose - emit close event to parent
     */
    onClose () {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  .image-container {
    display: none;
  }

  @media (min-width: 768px) {
    .success-modal-body {
      display: flex;
    }

    .image-container {
      display: block;
      flex: 0 0 50%;
    }

    .image-container img {
      max-width: 100%;
    }

    .text-container {
      margin-left: 10px;
      text-align: left;
    }
  }
</style>
