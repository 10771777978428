<template>
  <div class="sho-checkbox">
    <label v-if="required" class="required-check">* </label>
    <div class="input sho-checkbox-inner-container form-control" :class="{ error: hasError, }">
      <label>
        <input
          :id="name"
          class="sr-only"
          type="checkbox"
          :name="name"
          :aria-describedby="`error-${name}`"
          :aria-invalid="!!errorMessage"
          :aria-required="required"
          :checked="modelValue || checked"
          :disabled="disabled"
          :value="value"
          @change="e => $emit('update:modelValue', e.target.checked)"
        >
        <span class="checkbox" data-name="checkbox" />
        <!-- eslint-disable vue/no-v-html -->
        <span v-if="label" class="label" v-html="label" />
        <slot v-else />
        <!-- eslint-enable vue/no-v-html -->
      </label>
      <p v-if="errorMessage" :id="`error-${name}`" role="alert" data-name="field-error" :data-field-id="name" class="error-msg">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShoCheckbox',
  emits: ['update:modelValue'],
  props: {
    /**
     * Sets the <input> 'id' and 'name' attributes.
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * Indicates if the input is required, sets proper styling.
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Optional <input> value.
     */
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
    /**
     * Optional label.
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Used for 2-way binding via v-model.
     * Both modelValue and 'checked' should never be used together.
     */
    modelValue: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows external control over the <input>'s 'checked' attr.
     * Both modelValue and 'checked' should never be used together.
     */
    checked: {
      type: Boolean,
      default: false,
    },
    /**
     * Indicates if the input is disabled.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Error message to display for invalid input.
     */
    error: {
      type: String,
      default: null,
    },
  },
  computed: {
    /**
     * Indicates if an error string is present.
     * NOTE: Leaving this but unsure why the template check isn't just 'v-if="error"'
     * @returns {boolean}
     */
    hasError () {
      return this.error?.length > 0;
    },
    /**
     * Returns the error string, if one exists.
     * NOTE: Same as above here.
     * @returns {string}
     */
    errorMessage () {
      return this.error?.length ? this.error : '';
    },
  },
};
</script>

<style lang="scss" scoped>
  .sho-checkbox {
    display: flex;
    position: relative;
  }

  .sho-checkbox-inner-container {
    display: inline-block;
    padding: 0 0 0 42px;
    position: relative;
    text-align: left;
  }

  label {
    color: inherit;
  }

  input:focus-within + .checkbox {
    opacity: 0.5; /* TODO: Better focus state */
  }

  .required-check {
    color: red;
    left: 25px;
    margin-top: 5px;
    position: absolute;
  }

  .checkbox {
    background: url("~@assets/img/forms/checkbox-sprite-60px.png") no-repeat 0 0;
    background-size: 28px 56px;
    display: inline-block;
    height: 28px;
    left: 0;
    margin-right: 3px;
    position: absolute;
    top: 6px;
    width: 28px;
  }

  input:checked + .checkbox {
    background-position-y: -28px;
  }

  .error-msg {
    @extend %ds-caption-2;
    color: red;
    margin: 5px 0 0;
  }

  @media (min-width: 768px) {
    .sho-checkbox-inner-container {
      padding-left: 32px;
    }

    .checkbox {
      background-size: 20px 40px;
      height: 20px;
      width: 20px;
    }

    input:checked + .checkbox {
      background-position-y: -20px;
    }
  }

  /* Dark Theme Styles */
  .dark .sho-checkbox-inner-container .checkbox,
  .sho-checkbox-inner-container.dark .checkbox {
    background-image: url("~@assets/img/forms/checkbox-dark-sprite-60px.png");
  }
</style>
