<template>
  <div>
    <h2 class="title">It appears that your Mediacom Subscription does not include Showtime <sup>&reg;</sup></h2>
    <p>
      Showtime Anytime<sup>&reg;</sup> is available free to all Mediacom cable customers who currently subscribe to Showtime. If you
      believe you received this in error or if you would like to sign up for Showtime, please contact
      <a class="sho-link" target="_blank" href="https://mediacomcable.com/site/about_contact_chat.html">Mediacom Customer Support.</a>
    </p>
  </div>
</template>
