<template>
  <div>
    <h2 class="title">It appears that your Grande Communications Subscription does not include Showtime <sup>&reg;</sup></h2>
    <p>
      Showtime Anytime<sup>&reg;</sup> is available free to all Grande cable customers who currently subscribe to Showtime. If you
      believe you received this in error or if you would like to sign up for Showtime, please contact
      Grande Customer Support at
      <a href="tel://18662472633">1-866-247-2633</a>.
    </p>
  </div>
</template>
