import { differenceInYears } from 'date-fns';

export const validEmail = function (email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email) && email.trim().indexOf(' ') <= 0;
};

export const validPassword = function (password) {
  // Passwords must be between 6-24 characters with at least 1 letter and at least 1 number or special character
  const regex = /(?=^.*[A-Za-z]+)(?=^.*(?:[0-9]+|[!@#$%^&*]+))/;
  return password
    && password.length >= 6
    && password.length <= 24
    && regex.test(password);
};

export const validZip = function (zipcode) {
  const regex = /^[0-9]{5}(?:-[0-9]{4})?$/; // USA only
  return regex.test(zipcode);
};

export const validateIsOver18 = function (dob) {
  return differenceInYears(new Date(), new Date(dob)) >= 18;
};

export default {
  validEmail,
  validPassword,
  validZip,
  validateIsOver18,
};
