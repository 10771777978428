<template>
  <div class="gender-select">
    <label v-if="!inlineLabel">Gender</label>
    <div class="field">
      <ShoSelect
        :class="variant"
        name="gender"
        :options="options"
        :value="modelValue"
        @change="$emit('update:modelValue', $event.target.value)"
      />
      <!-- TODO: This should emit 'change' as well to match native <select> -->
    </div>
  </div>
</template>

<script>
import ShoSelect from '@components/ui/ShoSelect.vue';

const OPTIONS = ['Not Specified', 'Male', 'Female'];

/**
 * @displayName Gender Select
 */
export default {
  name: 'GenderSelect',
  components: { ShoSelect },
  emits: ['update:modelValue'],
  props: {
    inlineLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    modelValue: {
      type: String,
      default: 'Gender',
    },
    variant: {
      type: String,
      default: '',
    },
  },
  computed: {
    options () {
      if (this.inlineLabel) return ['Gender', ...OPTIONS];
      return OPTIONS;
    },
  },
};
</script>

<style lang="scss" scoped>
  label {
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  .select {
    width: 140px;
  }

  .field {
    width: 100%;
  }

  .gender-select {
    display: flex;
    margin: 0 0 10px;
    width: 100%;
  }

  @media (min-width: 800px) {
    label {
      margin: auto;
      width: 160px;
    }
  }
</style>
