<template>
  <ShoModal
    title-image-path="/assets/img/stat/icons/svg/showtime-logo.svg"
    @close="onClose"
  >
    <div class="form-copy">
      <h1>ACTIVATE YOUR DEVICE</h1>
      <p>Please enter your activation code. If you do not have a code, follow the activation instructions on your device to get one.</p>
    </div>

    <ShoForm class="activation-form" @close-form="onClose" @submit="onCodeSubmit">
      <div class="control-group">
        <ShoInput
          v-model="enteredCode" 
          label="Enter Your Code" 
          name="code"
          type="text"
          data-name="activation-form:code"
          animated 
          required
          :error="codeError"
          class="control-col-6"
        />
      </div>
      <div class="form-actions">
        <ShoButton variant="primary" block type="submit">Submit</ShoButton>
      </div>
    </ShoForm>
  </ShoModal>
</template>

<script>
import ShoModal from '@components/ui/ShoModal.vue';
import ShoButton from '@components/ui/ShoButton.vue';
import ShoInput from '@components/ui/ShoInput.vue';
import ShoForm from '@components/ui/ShoForm.vue';

/**
 * @displayName ActivationCodeForm
 */
export default {
  name: 'ActivationCodeForm',

  components: {
    ShoModal,
    ShoButton,
    ShoInput,
    ShoForm,
  },

  emits: ['close'],

  data () {
    return {
      enteredCode: '',
      codeError: null,
    };
  },

  methods: {
    /**
     * onClose - emit close event to parent
     */
    onClose () {
      this.$emit('close');
    },

    /**
     * Validate that an activation code was entered and store it to vuex,
     * emit event up to parent
     */
    onCodeSubmit () {
      this.codeError = null;
      if (!this.enteredCode) {
        this.codeError = 'Please enter a code.';
      } else {
        this.$store.dispatch('deviceActivation/setCode', this.enteredCode);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.form-copy {
  margin: 0 10px;
}

.form-copy h1 {
  font-size: 24px;
  margin-top: 0;
}

@media all and (min-width: 768px) {
  .form-copy {
    margin: 0 auto;
    max-width: 375px;
  }

  .sho-form {
    margin-left: auto;
    margin-right: auto;
    max-width: 375px;
  }
}
</style>
