<template>
  <div class="fullPickerPanel">
    <div class="mso-search-container">
      <ShoIcon type="search" alt="MSO Provider Search" />
      <input
        id="msoQ"
        ref="searchInput"
        v-model="searchInput"
        type="text"
        placeholder="Search All Providers"
        autocomplete="off"
      >
    </div>
    <ul class="fullPicker msos">
      <li
        v-for="(msoEl, index) in fullPickerList"
        :key="`mso-${index}`"
        :set="formattedMSO = highlightSearch(msoEl.logInText)"
        data-name="mso"
        :data-mso="msoEl.logInText"
        @click="$emit('mso-click', msoEl)"
      >
        <div class="search-result">
          {{ formattedMSO[0] }}<!--
          --><span class="highlight">{{ formattedMSO[1] }}</span><!--
          -->{{ formattedMSO[2] }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import ShoIcon from '@components/ui/ShoIcon.vue';

/**
 * @displayName Full Picker Panel
 */
export default {
  name: 'FullPickerPanel',

  components: {
    ShoIcon,
  },

  emits: ['mso-click'],

  props: {
    /**
     * App dictionary passed as a prop
     */
    dictionary: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data () {
    return {
      searchInput: '',
    };
  },

  computed: {
    /**
     * Array of all MSOs from dictionary
     */
    fullPickerList () {
      const msos = this.dictionary && this.dictionary.msos ? this.dictionary.msos : [];
      if (this.searchInput) {
        return msos.filter((mso) => mso.logInText.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1);
      }
      return msos;
    },
  },

  methods: {
    /**
     * highlightSearch
     * @param {String} msoName - display name for MSO
     * Given an MSO name, formats the string dependent on if there's a current user search filter for MSO providers
     */
    highlightSearch (msoName) {
      if (this.searchInput) {
        const startIdx = msoName.toLowerCase().indexOf(this.searchInput.toLowerCase());
        const endIdx = startIdx + this.searchInput.length;

        const pre = (startIdx > 0) ? msoName.slice(0, startIdx) : '';
        const searchedTerm = msoName.slice(startIdx, startIdx + this.searchInput.length);
        const post = (endIdx < msoName.length) ? msoName.slice(startIdx + this.searchInput.length) : '';
        return [pre, searchedTerm, post];
      }
      return [msoName, '', ''];
    },
  },
};
</script>

<style lang="scss" scoped>
  input {
    background: #222;
    border: 1px solid #fff;
    border-color: #222;
    color: #fff;
    padding: 10px 0;
    text-indent: 10px;
    width: 100%;
  }

  input:focus {
    background-color: black;
    outline: none;
  }

  ul,
  li {
    color: #999;
    cursor: pointer;
    list-style: none;
    padding: 5px 0;
    text-align: left;
  }

  ul li:hover {
    color: white;
    cursor: pointer;
  }

  .fullPickerPanel {
    height: 100%;
    -webkit-overflow-scrolling: touch;
    position: relative;
  }

  .fullPickerPanel .fullPicker {
    bottom: 0;
    left: 0;
    margin-right: 14px;
    overflow: scroll;
    overflow-x: hidden;
    padding-bottom: 60px;
    position: absolute;
    right: 0;
    top: 35px;
    width: 100%;
  }

  .fullPickerPanel .fullPicker > li > span {
    display: block;
    padding: 10px 0;
  }

  .fullPickerPanel .fullPicker > li > span.highlight {
    padding: 0;
  }

  .search-result .highlight {
    color: #55c5cf;
  }

  .fullPickerPanel .fullPicker ul.msos li {
    color: #999;
    cursor: pointer;
    padding: 5px 0;
    transition: 0.3;
  }

  .fullPickerPanel .fullPicker ul.msos li:hover {
    color: #fff;
  }

  .fullPickerPanel .fullPicker ul.msos li:hover .highlight {
    color: #fff;
  }

  .fullPickerPanel .fullPicker ul.msos li .highlight {
    color: #55c5cf;
  }

  .sho-icon {
    opacity: 0.75;
    padding-top: 35px;
    position: absolute;
    right: 10px;
    transform: translate(0, -50%);
    transition: opacity 0.2s;
  }

  @media (min-width: 768px) {
    .fullPickerPanel {
      height: 400px;
    }
  }
</style>
