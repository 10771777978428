<template>
  <div class="app-wrapper">
    <MSOPicker v-if="isMsoPickerVisible" />
    <STATRegistration v-if="isStatRegistrationVisible" />
    <STATActivation v-if="isStatActivationVisible" />
    <UpsellModal v-if="isUpsellModalVisible" />
    <MigrationModal v-if="shouldShowMigrationModal" v-bind="migrationModal" @close="onMigrationModalClose" />
    <iframe 
      v-if="externalLogoutURL"
      style="border: 0; display: block; height: 0; width: 0;"
      :src="externalLogoutURL"
    />
    <slot />
  </div>
</template>

<script>
import MSOPicker from '@components/ui/stat/MSOPicker.vue';
import STATRegistration from '@components/registration/STATRegistration.vue';
import STATActivation from '@components/activation/STATActivation.vue';
import UpsellModal from '@components/ui/stat/UpsellModal.vue';
import MigrationModal from '@components/paramount-migration/MigrationModal.vue';
import { PAYWALL_IMAGE_TYPES, STORAGE_KEYS } from '@utils/constants.js';

import { mapGetters, mapState } from 'vuex';

import Logger from '@utils/logger.js';
const logger = new Logger('STAT App Wrapper');

export default {
  components: {
    MigrationModal,
    MSOPicker,
    STATRegistration,
    STATActivation,
    UpsellModal,
  },

  emits: ['ready'],
  
  data () {
    return {
      externalLogoutURL: '',
      migrationModalClosed: true,
    };
  },

  computed: {
    ...mapGetters('upsellModal', { isUpsellModalVisible: 'isVisible' }),
    ...mapState('msoPicker', { isMsoPickerVisible: 'isVisible' }),
    ...mapState('statRegistration', { isStatRegistrationVisible: 'isVisible' }),
    ...mapState('statActivation', { isStatActivationVisible: 'isVisible' }),
    ...mapState('viewerRestrictions', { isParentalControlsVisible: 'isVisible' }),
    ...mapState('deviceActivation', { activationCode: 'code'  }),
    ...mapState('mso', ['externalLogout', 'logOutLink']),
    ...mapState('user', { userRegistered: 'registered', mso: 'mso', msoId: 'msoId', tveUserId: 'tveUserId', userError: 'error' }),
    ...mapState('dictionary', ['homeScreenModal']),
    
    /**
     * Parses dictionary data for the MigrationModal UI.
     * Grabs the proper mobile and desktop images from the image list
     * and matches their formatting to a paywall response.
     * @returns {(Object|null)}
     */
    migrationModal () {
      if (!this.homeScreenModal) {
        return null;
      }

      const parsed = { ...this.homeScreenModal, ...this.homeScreenModal.content, images: [] };
      if (this.homeScreenModal.images) {
        const desktopImage = this.homeScreenModal.images.find((image) => image.width === 1920 && image.url.indexOf('web') > -1);
        const mobileImage = this.homeScreenModal.images.find((image) => image.width === 768 && image.url.indexOf('web') > -1);

        if (desktopImage) {
          parsed.images.push({ ...desktopImage, type: PAYWALL_IMAGE_TYPES.hero });
        }

        if (mobileImage) {
          parsed.images.push({ ...mobileImage, type: PAYWALL_IMAGE_TYPES.heroMobile });
        }
      }

      delete parsed.content;
    
      return parsed;
    },
    /**
     * Indicates if the migration modal should be displayed.
     * Checks for modal content and if the user has already closed the modal this session.
     * @returns {boolean}
     */
    shouldShowMigrationModal () {
      if (!this.homeScreenModal || !this.$config.statMigrationEnabled) {
        return false;
      }

      return !this.migrationModalClosed;
    },
  },
  
  watch: {
    tveUserId: {
      deep: true,
      handler (newValue) {
        if (newValue && this.externalLogoutURL) {
          this.externalLogoutURL = '';
        }
      },
    },
    /**
     * Handle user registration errors
     */
    userError: {
      immediate: true,
      handler (error) {
        if (error) {
          this.$store.dispatch('modal/show', error);
        }
      },
    },
    /**
     * Check migration modal visibility on route change.
     */
    $route () {
      this.checkMigrationModal();
    },
  },

  beforeCreate () {
    this.$store.commit('deviceActivation/initializeState');
  },
  
  /**
   * Cleanup on component unmount.
   */
  beforeUnmount () {
    window.removeEventListener('user-logout', this.onLogoutRequested);
  },

  /**
   * Created lifecycle hook
   */
  async created () {
    this.checkMigrationModal();
    window.addEventListener('user-logout', this.onLogoutRequested);
    this.$emit('ready');
  },
  
  methods: {
    /**
     * Dispatches user logout action and requests user's MSO logout method if one exists
     */
    async onLogoutRequested () {
      this.$store.dispatch('startLoading');
      const msoId = this.msoId;
      await this.$store.dispatch('user/logout');
      if (this.mso || msoId) {
        if (!this.logOutLink) await this.$store.dispatch('mso/getMSOData', msoId);
        if (this.externalLogout) {
          logger.log('Embedding iframe for external MSO logout to circumvent CORS restrictions');
          this.externalLogoutURL = this.logOutLink;
        } else {
          await this.$store.dispatch('mso/logout');
        }
      }
      if (this.$route.name !== 'home') {
        this.$router.replace({ name: 'home' });
      }
      this.$store.dispatch('stopLoading');
    },
    /**
     * Checks if the migration modal should be revealed.
     * Assumes not visible to start - modal is displayed if the user has 
     * not closed it during this session and is not currently on a playback route.
     */
    checkMigrationModal () {
      if (['play', 'playLive'].indexOf(this.$route.name) > -1) {
        return false;
      }

      const dismissed = sessionStorage.getItem(STORAGE_KEYS.migrationModalDismissed);
      if (!dismissed) {
        this.migrationModalClosed = false;
      }
    },
    /**
     * Handles the migration modal 'close' event.
     */
    onMigrationModalClose () {
      this.migrationModalClosed = true;
      sessionStorage.setItem(STORAGE_KEYS.migrationModalDismissed, true);
    },
  },
};
</script>
