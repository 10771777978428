<template>
  <ShoModal title="Welcome to Showtime Anytime" @close="onClose">
    <div class="success-body">
      <img
        :src="deviceLockupImage"
        class="lockup"
        alt="Supported devices include smart phones, tablets, smart tvs, laptops, and PCs."
      >
      <div class="text-container">
        <p>You are now registered for Showtime Anytime.</p>
        <p>You now have unlimited access to a huge selection of programming on your TV, tablet, phone, and computer.</p>
        <MobileAppButton v-if="isSupportedMobileDevice" />
      </div>
    </div>
    <ShoButton variant="primary" class="cta-button" @click="onClose">OK</ShoButton>
  </ShoModal>
</template>

<script>
import ShoModal from '@components/ui/ShoModal.vue';
import ShoButton from '@components/ui/ShoButton.vue';
import MobileAppButton from '@components/ui/stat/MobileAppButton.vue';

import { isSupportedMobileDevice } from '@utils/mobile-detect.js';
import ls from '@utils/local-storage.js';
import { STORAGE_KEYS } from '@utils/constants.js';

export default {
  name: 'RegistrationSuccess',
  components: {
    MobileAppButton,
    ShoButton,
    ShoModal,
  },
  data () {
    return {
      deviceLockupImage: `${__BASE_IMAGE_URL__}/frontdoor/stat_aboutlockup_720.png`,
    };
  },
  emits: ['close'],
  computed: {
    isSupportedMobileDevice () {
      return isSupportedMobileDevice();
    },
  },
  methods: {
    onClose () {
      this.$emit('close');

      // Check for a saved route
      const savedRoute = ls.getItem(STORAGE_KEYS.intendedRoute);
      ls.removeItem(STORAGE_KEYS.intendedRoute);

      // Navigate to saved route if it exists
      if (savedRoute) this.$router.push(savedRoute);
    },
  },
};
</script>

<style lang="scss" scoped>
  .success-body {
    height: auto;
  }

  .lockup {
    width: 100%;
  }

  .cta-button {
    cursor: pointer;
    margin-top: 25px;
    width: 100%;
  }

  @media (min-width: 768px) {
    .success-body {
      display: flex;
    }

    .lockup {
      width: 50%;
    }

    .text-container {
      margin-left: 10px;
      text-align: left;
    }
  }
</style>
