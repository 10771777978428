<template>
  <ShoInput
    :name="name"
    :label="label"
    :value="date"
    :error="error"
    placeholder="mm/dd/yyyy"
    autocomplete="off"
    @input="onEnterDate"
    @focus="onFocus"
    @blur="onBlur"
  />
</template>
<script>
import ShoInput from '@components/ui/ShoInput.vue';
/**
 * @displayName Sho Masked Input
 */
export default {
  components: {
    ShoInput,
  },
  emits: ['update', 'focus', 'blur'],
  props: {
    /**
    * Input label
    */
    label: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * Name of DOB field, used for id on {@link ShoInput}
     */
    name: {
      type: String,
      required: true,
    },
    error: {
      type: [Boolean, String],
      default: null,
    },
  },
  data () {
    return {
      date: '',
      charIdx: -1,
    };
  },
  methods: {
    /**
     * Given a numeric input, replaces 'm','d', or 'y' in date data
     * with number input by user, and skips over forward slashes. 
     * Emits updated date via 'update'.
     * @param {InputEvent} e - Date field input event.
     */
    onEnterDate (e) {
      const val = e.target.value;
      const placeholder = 'mm/dd/yyyy';
      const regex = /\D/g;
      const input = this.$el.querySelector('input');
      let newVal = val.replace(regex, '').split('');
      let cursorPos = newVal.length;

      // insert slashes
      if (newVal.length > 4) {
        newVal.splice(4, 0, '/');
        newVal.splice(2, 0, '/');
        cursorPos += 2;
      } else if (newVal.length > 2) {
        newVal.splice(2, 0, '/');
        cursorPos += 1;
      }

      newVal = newVal.join('');
      if (newVal.length < 10) {
        newVal = newVal + placeholder.substring(newVal.length);
      }

      if (newVal.length > 10) {
        newVal = newVal.substring(0, 10);
      }

      this.date = newVal;
      input.value = newVal;
      this.$emit('update', this.date);
       
      this.$nextTick(() => {  
        input.selectionEnd = this.charIdx = cursorPos;
      });
    },
    /**
     * input focus event
     * @event focus
     */
    onFocus () {
      const input = this.$el.querySelector('input');
      // For some reason, you can't set the selection directly on a focus event and
      // $nextTick also didn't work
      window.setTimeout(() => input.selectionEnd = this.charIdx + 1, 0);
      this.$emit('focus');
    },
    /**
     * input blur event
     * @event blur
     */
    onBlur () {
      this.$emit('blur');
    },
  },
};
</script>
<style lang="scss" scoped>
</style>

