<template>
  <div 
    class="input sho-input form-control" 
    :data-type="getType" 
    :class="componentClass" 
    data-name="sho-input"
    :data-input="name"
  >
    <label v-if="!animated && label" :id="`sho-input-label-${name}`" :for="name">{{ label }}</label>

    <input
      :id="name"
      :aria-labelledby="`sho-input-label-${name} sho-input-label-animated-${name}`"
      :type="getType"
      :name="name"
      :value="modelValue || value"
      :data-recurly="recurlyKey"
      :aria-describedby="`error-${name} helper-${name}`"
      :aria-invalid="!!errorMessage"
      :aria-required="required"
      :disabled="disabled"
      :tabindex="tabindex"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :autocomplete="autocomplete"
      @input="e => $emit('update:modelValue', e.target.value)"
    >

    <label v-if="animated && label" :id="`sho-input-label-animated-${name}`" :for="name">{{ label }}</label>

    <p v-if="type === 'password'" class="sr-only" aria-live="polite">Password {{ passwordShown ? 'shown' : 'hidden' }}</p>
    <button
      v-if="type === 'password'"
      type="button"
      role="switch"
      :aria-pressed="passwordShown"
      :aria-label="passwordShown ? 'hide password' : 'show password'"
      class="password-toggle"
      @click.prevent="togglePassword"
    >
      {{ passwordShown ? 'HIDE' : 'SHOW' }}
    </button>

    <p v-if="errorMessage" :id="`error-${name}`" role="alert" data-name="field-error" :data-field-id="name" class="error-msg">{{ errorMessage }}</p>
    <p v-if="helper" :id="`helper-${name}`" class="helper">{{ helper }}</p>
  </div>
</template>

<script>

export default {
  name: 'ShoInput',
  emits: ['update:modelValue'],
  props: {
    /**
     * Sets the <input> 'id' and 'name' attributes.
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * Indicates the <input> type.
     */
    type: {
      type: String,
      default: 'text',
    },
    /**
     * Used for 2-way binding via v-model.
     * Both modelValue and value should never be used together.
     */
    modelValue: {
      type: [String, Number],
      default: null,
    },
    /**
     * Used for setting the input value via a 'native' value attr.
     * Both modelValue and value should never be used together.
     */
    value: {
      type: [String, Number],
      default: null,
    },
    /**
     * Input label.
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Binds native elements used as Recurly form inputs to Recurly's SDK.
     */
    recurlyKey: {
      type: String,
      default: null,
    },
    /**
     * Form input hint.
     */
    helper: {
      type: String,
      default: null,
    },
    /**
     * Indicates if the input is required, sets proper styling.
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Input placeholder string.
     */
    placeholder: {
      type: [String, Number],
      default: null,
    },
    /**
     * Indicates if the input is disabled.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Sets the input's tabindex attr.
     */
    tabindex: {
      type: Number,
      required: false,
      default: null,
    },
    /**
     * Indicates the input is to use the animated placeholder style.
     */
    animated: {
      type: Boolean,
      default: false,
    },
    /**
     * Error string (or boolean) for displaying error and setting styles.
     */
    error: {
      type: [Boolean, String],
      default: false,
    },
    /**
     * Sets the native input maxlength attr.
     */
    maxlength: {
      type: Number,
      default: null,
    },
    /**
     * Sets the native input maxlength attr.
     */
    autocomplete: {
      type: String,
      default: null,
    },
    /**
     * Style variants.
     */
    variant: {
      type: String,
      default: null,
      validator (value) {
        return !value || value === 'dark';
      },
    },
  },
  data () {
    return {
      passwordShown: false,
    };
  },
  // TODO: STAT-34869 Move common form input methods and computeds to a mixin
  computed: {
    componentClass () {
      const classArray = [{
        required: this.required,
        error: this.hasError,
        animated: this.animated,
        empty: this.isEmpty,
      }];

      if (this.variant) {
        classArray.push(this.variant);
      }

      return classArray;
    },
    isEmpty () {
      return (!this.value && !this.modelValue) || (!this.value?.length && !this.modelValue?.length);
    },
    getType () {
      if (this.type !== 'password') {
        return this.type;
      }
      return this.passwordShown ? 'text' : 'password';
    },
    hasError () {
      if (typeof this.error === 'string') {
        return !!this.error.length;
      }
      return this.error;
    },
    errorMessage () {
      if (this.error && typeof this.error === 'string' && this.error.length) {
        return this.error;
      }
      return '';
    },
  },
  methods: {
    togglePassword () {
      this.passwordShown = !this.passwordShown;
    },
  },
};
</script>

<style lang="scss" scoped>
  .sho-input {
    position: relative;
    text-align: left;
    width: 100%;
  }

  input {
    @extend %ds-input;
    background: white;
    border: 1px solid black;
    box-sizing: border-box;
    color: black;
    height: 40px;
    position: relative;
    text-indent: 10px;
    width: 100%;
    z-index: 1;
  }

  input:focus {
    border-color: $ds-medium_grey;
    outline: none;
  }

  input:disabled {
    background-image: url("~@assets/img/icons/invalid.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: 32px;
    color: $ds-medium_grey;
  }

  label {
    @extend %ds-caption-2;
    display: inline-block;
    padding-bottom: 5px;
    z-index: 2;
  }

  .required label::before {
    color: red;
    content: "* ";
  }

  .required input::placeholder {
    color: red;
  }

  .helper {
    @extend %ds-caption-2;
    text-align: left;
  }

  .helper:last-child {
    margin-bottom: 0;
  }

  .error input {
    border-color: red;
  }

  .error label,
  .error-msg {
    color: red;
  }

  .error-msg {
    @extend %ds-caption-2;
    margin: 5px 0 0;
  }

  .password-toggle {
    @extend %ds-input-control;
    background: none;
    border: none;
    color: $ds-medium_grey;
    cursor: pointer;
    display: block;
    padding: 5px;
    position: absolute;
    right: 5px;
    top: 52px; /* At small screen widths the label text wraps so we push down the toggle button with it */
    z-index: 2;
  }

  /* Animated label styles */

  .animated input {
    height: 50px;
    padding-top: 20px;
  }

  .animated label {
    color: black;
    padding: 0;
    transition: all 0.2s ease;
  }

  .animated.empty input:focus + label,
  .animated label {
    @extend %ds-input-placeholder-sm;
    left: 10px;
    pointer-events: none;
    position: absolute;
    text-transform: uppercase;
    top: 6px;
  }

  .animated.empty label {
    @extend %ds-input-placeholder;
    text-transform: none;
    top: 18px;
  }

  .animated .password-toggle {
    top: 15px;
  }

  /* Dark theme styles */

  .dark input {
    background: $ds-black;
    border-color: $ds-medium_grey;
    color: $ds-white;

    &::placeholder {
      color: $ds-sho_neutral_light;
    }
  }

  .dark.animated label {
    color: $ds-sho_neutral_light;
  }

  @media (min-width: 333px) {
    .password-toggle {
      top: 30px;
    }
  }
</style>
