<template>
  <a :href="platform.downloadUrl" class="btn-mobile" :class="platform.cssClass">{{ platform.buttonText }}</a>
</template>
<script>
import { getDeviceType } from '@utils/mobile-detect.js';
/**
 * @displayName Mobile App Button
 */
export default {
  name: 'MobileAppButton',
  data () {
    return {
      deviceConfigs: {
        ios: {
          downloadUrl: 'http://itunes.apple.com/us/app/id484232467',
          cssClass: 'app-store',
          buttonText: 'Download on the App Store',
        },
        amazon: {
          downloadUrl: 'http://www.amazon.com/Showtime-Anytime-Kindle-Tablet-Edition/dp/B00B4G3MV8/',
          cssClass: 'amazon-store',
          buttonText: 'Download on Amazon',
        },
        android: {
          downloadUrl: 'market://details?id=com.showtime.showtimeanytime',
          cssClass: 'android-store',
          buttonText: 'Download on Google Play',
        },
      },
    };
  },
  computed: {
    /**
     * Returns device type
     * @returns {string}
     */
    deviceType () {
      return getDeviceType();
    },

    /**
     * Returns the platform object for the device user is currently on
     * @returns {object}
     */
    platform () {
      return this.deviceConfigs[this.deviceType];
    },
  },

};
</script>
<style lang="scss" scoped>
  .btn-mobile {
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    height: 40px;
    margin-right: 7px;
    margin-top: 5px;
    overflow: hidden;
    text-indent: -1000px;
    width: 137px;
  }

  .app-store {
    background-image: url("~@assets/img/badges/app-store-tablet-retina.png");
  }

  .amazon-store {
    background-image: url("~@assets/img/badges/amazon-badge-desktop.png");
  }

  .android-store {
    background-image: url("~@assets/img/badges/android-store-tablet-retina.png");
  }
</style>
