<template>
  <div>
    <h2 class="title">It appears that your Blue Ridge Communications Subscription does not include Showtime <sup>&reg;</sup></h2>
    <p>
      Showtime Anytime<sup>&reg;</sup> is available free to all Blue Ridge Communications cable customers who currently subscribe to Showtime. If you
      believe you received this in error or if you would like to sign up for Showtime, please contact
      <a class="sho-link" target="_blank" href="http://www.brctv.com/support/contactus">Blue Ridge Communications Customer Support</a> at
      <a href="tel://18002225377">1-800-222-5377</a>.
    </p>
  </div>
</template>
