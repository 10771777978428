<template>
  <ShoModal
    title="Select Your Device"
    :loading="!platforms || submitting"
    @close="onClose"
  >
    <ShoForm class="device-select-form" @close-form="onClose" @submit="onSubmit">
      <div class="control-group">
        <ShoSelect
          v-model="selectedDevice"
          :options="deviceList" 
          name="device"
          data-name="activation-form:device"
          required
          :error="deviceError"
          class="control-col-6"
        />
      </div>
      <div class="form-actions">
        <ShoButton variant="primary" block type="submit">Submit</ShoButton>
      </div>
    </ShoForm>
  </ShoModal>
</template>

<script>
import ShoModal from '@components/ui/ShoModal.vue';
import ShoButton from '@components/ui/ShoButton.vue';
import ShoSelect from '@components/ui/ShoSelect.vue';
import ShoForm from '@components/ui/ShoForm.vue';

import { mapState } from 'vuex';

/**
 * @displayName SelectDeviceForm
 */
export default {
  name: 'SelectDeviceForm',

  components: {
    ShoModal,
    ShoButton,
    ShoSelect,
    ShoForm,
  },

  emits: ['close', 'submit'],

  data () {
    return {
      selectedDevice: '',
      deviceError: null,
      submitting: false,
    };
  },

  computed: {
    ...mapState('user', ['msoId']),
    ...mapState('mso', { platforms: (state) => state.platforms?.activate }),
    deviceList () {
      return this.platforms ? this.platforms.map((platform) => ({ text: platform.displayName, value: platform.platform  })) : [];
    },
  },

  async created () {
    if (!this.platforms) {
      await this.$store.dispatch('mso/getMSOData', this.msoId);
    }
    this.selectedDevice = this.deviceList?.[0]?.value;
  },

  methods: {
    /**
     * onClose - emit close event to parent
     */
    onClose () {
      this.$emit('close');
    },

    /**
     * Validate that a device was selected,
     * then emit submit event
     */
    async onSubmit () {
      this.deviceError = null;
      if (!this.selectedDevice) {
        this.deviceError = 'Please select a device.';
      } else {
        this.submitting = true;
        this.$emit('submit', this.selectedDevice);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

@media all and (min-width: 768px) {
  .sho-form {
    margin-left: auto;
    margin-right: auto;
    max-width: 360px;
  }

  @media (min-width: 768px) {
    .sho-select :deep(.option-list) {
      max-height: 275px;
    }
  }
}
</style>
