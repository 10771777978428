<template>
  <div>
    <RegistrationForm
      v-if="isRegistrationFormVisible"
      @form-close="onFormClose"
      @form-submitted="onFormSubmitted"
    />
    <ParentalControlsModal
      v-if="areParentalControlsVisible"
      @close="onParentalControlsClose"
    />
    <PIINotificationModal
      v-if="isPIINotificationVisible"
      @close="isPIINotificationVisible = false && handleRegistrationComplete()"
    />
    <RegistrationSuccess
      v-if="isRegistrationSuccess"
      @close="onRegistrationSuccessClose"
    />
  </div>
</template>

<script>
import RegistrationForm from '@components/registration/RegistrationForm.vue';
import ParentalControlsModal from '@components/registration/parental-controls/ParentalControlsModal.vue';
import PIINotificationModal from '@components/registration/PIINotificationModal.vue';
import RegistrationSuccess from '@components/registration/RegistrationSuccess.vue';

import { mapState } from 'vuex';
import { ACCOUNT_TYPES, MSO_TYPES } from '@utils/constants.js';

/**
 * @displayName STAT Registration
 */
export default {
  name: 'STATRegistration',

  components: {
    ParentalControlsModal,
    PIINotificationModal,
    RegistrationForm,
    RegistrationSuccess,
  },

  data () {
    return {
      isRegistrationFormVisible: true,
      areParentalControlsVisible: false,
      isPIINotificationVisible: false,
      isRegistrationSuccess: false,
    };
  },

  computed: {
    ...mapState('mso',
      {
        msoAccountType: 'accountType',
        parentalControls: 'parentalControls',
      }
    ),
    ...mapState('user', ['accountType']),
    ...mapState('deviceActivation', { activationCode: 'code'  }),
    /**
     * Returns true if registering user is allowed to set viewer restrictions
     * @returns {boolean}
     */
    canSetViewerRestrictions () {
      return (this.msoAccountType === MSO_TYPES.primarySub || this.msoAccountType === MSO_TYPES.primaryOnly) && this.accountType === ACCOUNT_TYPES.primary && this.parentalControls === 'SHOWTIME';
    },
  },

  methods: {
    /**
     * onFormClose - Closes STAT registration form modal and logs out user
     */
    onFormClose () {
      this.$store.dispatch('statRegistration/close');
      window.dispatchEvent(new CustomEvent('user-logout'));
    },
    /**
     * onFormSubmitted - handle next registration / activation step
     * @param {boolean} showPiiMessage - true if Pii message should be shown
     */
    onFormSubmitted (showPiiMessage) {
      this.isRegistrationFormVisible = false;

      if (showPiiMessage) {
        this.isPIINotificationVisible = true;
      } else if (this.canSetViewerRestrictions) {
        this.areParentalControlsVisible = true;
      } else {
        this.handleRegistrationComplete();
      }
    },
    /**
     * onParentalControlsClose - close parental controls
     */
    onParentalControlsClose () {
      this.areParentalControlsVisible = false;
      this.handleRegistrationComplete();
    },
    onRegistrationSuccessClose () {
      this.$store.dispatch('statRegistration/close');
    },
    /**
     * handleRegistrationComplete - either show success or continue to activation flow
     */
    handleRegistrationComplete () {
      if (this.activationCode) {
        this.$store.dispatch('statRegistration/close');
        this.$store.dispatch('statActivation/show');
      } else {
        this.isRegistrationSuccess = true;
      }
    },
  },
};
</script>
