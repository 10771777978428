<template>
  <div>
    <h2 class="title">It appears that your AT&T U-Verse<sup>&reg;</sup> Subscription does not include Showtime<sup>&reg;</sup></h2>
    <p>
      Showtime Anytime<sup>&reg;</sup> is available free to all AT&T U-Verse customers who subscribe to Showtime. If you
      believe you received this in error, please contact AT&T at <a href="tel://18002882020">1-800-ATT-2020</a> or
      visit <a class="sho-link" target="_blank" href="http://www.att.com/uverse">http://www.att.com/uverse.</a>
    </p>
    <p>
      If you would like to sign up for Showtime, you can do so directly from your television by going to channel 9910 and following the
      instructions. You may also tell AT&T directly at <a href="tel://18002882020">1-800-ATT-2020</a> or visit
      <a class="sho-link" target="_blank" href="http://www.att.com/olam">http://www.att.com/olam.</a>
    </p>
  </div>
</template>
