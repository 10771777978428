<template>
  <ShoModal
    title="Parental Controls"
    :loading="fetching"
    @close="onClose"
  >
    <div>
      <div class="body-headers">
        <label class="section-label">SET PARENTAL CONTROLS</label>
        <p class="no-spacing">Parental Controls enable you to set maximum program ratings allowed.</p>
      </div>
      <div class="container">
        <div class="account-section">
          <label class="section-label">YOUR ACCOUNT</label>
          <p class="no-spacing">Set maximum ratings allowed for your account.</p>
        </div>
        <div class="dropdown-section">
          <RestrictionSelector
            v-model="movie"
            name="movie"
            type="movie"
          />
          <RestrictionSelector
            v-model="tv"
            name="tv"
            type="tv"
          />
        </div>
          
        <template v-if="isSubAccountMso">
          <div class="account-section">
            <label class="section-label">SUB-ACCOUNTS: DEFAULT SETTING</label>
            <p class="no-spacing">Set the default maximum ratings allowed for all your sub-accounts. As your sub-accounts register, you can return to your SETTINGS page to set individual maximums for each account.</p>
          </div>
          <div class="dropdown-section">
            <RestrictionSelector
              v-model="defaultMovie"
              name="defaultMovie"
              type="movie"
            />
            <RestrictionSelector
              v-model="defaultTv"
              name="defaultTv"
              type="tv"
            />
          </div>
        </template>
      </div>
      

    </div>
      
    <div class="buttons">
      <ShoButton variant="primary" @click="onSubmit">Enable Controls</ShoButton>
      <ShoButton @click="onClose">Not Now</ShoButton> <!-- TODO:  Needs to direct user to next step in registration. (see STAT-36611) -->
    </div>
  </ShoModal>
</template>

<script>
import ShoModal from '@components/ui/ShoModal.vue';
import ShoButton from '@components/ui/ShoButton.vue';
import RestrictionSelector from '@components/settings/viewing-preferences/RestrictionSelector.vue';

import { mapState } from 'vuex';


/**
 * @displayName Parental Controls Form Modal
 */
export default {
  name: 'ParentalControlsFormModal',

  components: {
    ShoModal,
    ShoButton,
    RestrictionSelector,
  },

  emits: ['close', 'submit'],

  props: {
    /**
     * User's MSO account type.
     */
    userAccountType: {
      type: String,
      required: true,
    },
    /**
     * Indicates if the user's MSO supports sub-accounts.
     */
    isSubAccountMso: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      defaultMovie: 'MOVIE_NR',
      defaultTv: 'TV_NR',
      movie: 'MOVIE_NR',
      tv: 'TV_NR',
    };
  },

  computed: {
    ...mapState('viewerRestrictions', { viewerRestrictions: (state) => state }),
    ...mapState('viewerRestrictions', ['fetching']),
    /**
     * Builds payload for form submit.
     * @returns {Object}
     */
    payload () {
      return {
        movie: this.movie,
        tv: this.tv,
        defaultMovie: this.defaultMovie,
        defaultTv: this.defaultTv,
        typeOfAccount: this.userAccountType.toLowerCase(),
      };
    },
  },
  methods: {
    /**
     * Bubbles up event to parent component and passes the settings object which have been updated.
     */
    onSubmit () {
      this.$emit('submit', this.payload);
    },
    /**
     * Handles close clicks and emits event to parent.
     */
    onClose () {
      this.$emit('close');
    },
  },
};

</script>

<style lang="scss" scoped>
p {
  color: $ds-sho_neutral_light;
}

.no-spacing {
  margin: 0;
}

.body-headers {
  text-align: left;
}

.dropdown-section {
  display: flex;
  flex-wrap: wrap;
}

.dropdown-section :deep(.field) {
  flex: 100%;
  margin-right: 0;
  padding-bottom: 1%;
  width: 100%;
}

.dropdown-section :deep(.select) {
  width: auto;
}

.container {
  text-align: left;
  width: 100%;
}

.section-label {
  margin-bottom: 2%;
  margin-left: 0;
  width: auto;
}

.account-section {
  padding-bottom: 2%;
  padding-top: 2%;
}

@media (min-width: 768px) {
  .dropdown-section .field {
    flex: 1;
  }

  .dropdown-section .field:first-child {
    margin-right: 8px;
  }

  .dropdown-section .field:last-child {
    margin-left: 8px;
  }
}

</style>
