<template>
  <div>
    <h2 class="title">It appears that your CenturyLink Prism Subscription does not include Showtime <sup>&reg;</sup></h2>
    <p>
      Showtime Anytime<sup>&reg;</sup> is available free to all Prism TV customers who currently subscribe to Showtime. If you
      believe you received this message in error or if you would like to sign up for Showtime, please contact
      <a class="sho-link" target="_blank" href="http://www.centurylink.com/help/guidance/prism.php">CenturyLink Customer Support</a> at
      <a href="tel://18002014099">1-800-201-4099</a>.
    </p>
  </div>
</template>
