<template>
  <div>
    <h2 class="title">It appears that your Time Warner Cable Subscription does not include Showtime <sup>&reg;</sup></h2>
    <p>
      Showtime Anytime<sup>&reg;</sup> is available free to Time Warner Cable customers who subscribe to Showtime.
      To sign up for Showtime, please visit
      <a class="sho-link" target="_blank" href="http://www.timewarnercable.com">http://timewarnercable.com</a>
    </p>
  </div>
</template>
